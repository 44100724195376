import { useReducer, useEffect, ChangeEvent, cloneElement, type JSX } from 'react';
import {reducer, flash} from 'lib';
import {
    PrimaryButton,
    CancelButton,
    GenericObject,
    CdnImage
} from 'components';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField';
import { DocumentSnapshot, updateDoc, getDocs, query, where } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const PublishModal = ({
    trigger = <IconButton><RssFeedIcon color="info"/></IconButton>,
    product,
    source,
}:{
    trigger?: JSX.Element
    product: DocumentSnapshot,
    source: GenericObject,
}) => {

    const [{
        open = false,
        loading = false,
        message,
    }, dispatch] = useReducer(reducer, {});

    const {
        callable,
    } = useFirebaseContext();

    const {organization} = useSessionContext();
    
    const {facebookCommentsRef} = useRefs();

    useEffect(() => {
        (async () => {
            if (!open || !facebookCommentsRef || !product || !organization || message) return;
            let result = `Lot #${product.get('lot')}: ${product.get('name')} $${product.get('price')} (${product.get('qty')} avail) ${product.get('description') ? ` - ${product.get('description')}` : ''}`;
            // see if there is a published comment for this product already 
            if (!message) {
                const q = await getDocs(
                    query(
                        facebookCommentsRef(organization.id, source?.id),
                        where('product.id', '==', product.id)
                    )
                )
                if (!!q?.docs?.length) {
                    result = q.docs[0].get('message');
                }
            }
            dispatch({
                message: result,
            })
        })();
    },[open,product,organization,source,facebookCommentsRef,message]);
 
    const toggleOpen = () => dispatch({ open: !open });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ [name]: value });
    }

    const handleClose = () => {
        dispatch({ open: false, message: null });
    }

    const publish = async () => {

        dispatch({ loading: true });

        try {
            const { data } = await callable!('facebook-publish', {
                action: 'comment',
                message,
                image: product.get('image') || null,
                postId: source.id,
                productId: product.id,
            });
            if (data?.success) {
                flash.success('Comment successfully published.');
                // update inventory record
                await updateDoc(product.ref, { published: true });
                handleClose();
            } else {
                flash.error(`Error publishing post: ${data?.error || 'Unknown error'} `);
            }
        } catch (err: any) {   
            flash.error(`Error publishing comment: ${err.message}`);
        } finally {
            dispatch({ loading: false });
        }
    }

    const Trigger = () => cloneElement(trigger, { disabled: open, onClick: toggleOpen} )

    return (
        <>
            <Trigger/>
            <Dialog
                open={open}
                onClose={toggleOpen}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle>
                    {product.get('published') ? 'Re-publish' : 'Publish'} Product to Post: {source.name}
                </DialogTitle>
                <DialogContent dividers>
                    <Box mb={2}>
                        <Typography>
                            The following comment will be added to your post. You may edit the comment before posting.
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            autoFocus
                            fullWidth
                            multiline
                            label="Comment"
                            name="message"
                            value={message}
                            onChange={handleChange}
                        />
                    </Box>
                    {product.get('image') && (
                        <CdnImage src={product.get('image')} style={{ margin: '0 auto' }}/>
                    )}
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleClose} disabled={loading}/>
                    <PrimaryButton
                        startIcon={<RssFeedIcon/>}
                        onClick={publish}
                        disabled={loading}
                        loading={loading}
                    >
                        {product.get('published') ? 'Re-publish' : 'Publish'}
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    )
}