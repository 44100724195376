import {useReducer, useEffect, ChangeEvent} from 'react';
import {
  reducer,
  flash,
  debug,
} from 'lib';
import escapeRegExp from 'lodash/escapeRegExp';
import {
  Uploader,
  ItemTypeIcon,
  PrimaryButton, 
  AlertBox,
} from 'components';
import { ConfirmModal, EditItemModal } from 'modals';
import {
  query,
  where,
  increment,
  serverTimestamp,
  DocumentSnapshot,
  orderBy,
} from 'firebase/firestore';
import {useSessionContext, useFirebaseContext, useRefs} from 'hooks';
import {ShopifyProductImportModal} from './components';
import {useCollection} from 'react-firebase-hooks/firestore';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack';
import {DndContext, DragEndEvent} from '@dnd-kit/core';
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import { SortableItem } from './components/SortableItem';
import Divider from '@mui/material/Divider';
import { InventorySource } from '@kwixl/interface';

interface InventoryProps {
    source?: InventorySource;
    disabled?: boolean;
}

export const Inventory = ({
    source = {
      id: '',
      type: 'none',
      name: '',
    },
    disabled = false,
}: InventoryProps) => {

    const {
        mobile,
        channel,
        organization, 
    } = useSessionContext();

    const {
      firebaseUser, 
      getBatch,
      defaultListenerOptions,
    } = useFirebaseContext();

    const {
      inventoryRef,
      inventoryItemRef,
    } = useRefs();

    const [{
        filter = '',
        maxItems = 50,
        checkedItems = [],
        products = [],
    }, dispatch] = useReducer(reducer, {});

    const sourceType = source?.id ? 'source.id' : 'source.type';
    const sourceId = source?.id ? source.id : source?.type ? source.type : 'x';
    const sourceCompare = source?.id || source?.type ? '==' : '!=';
    
    const [inventory] = useCollection(
      query(
        inventoryRef!(organization?.id || 'X'),
        where(sourceType, sourceCompare, sourceId),
        where('deletedAt', '==', null),
        orderBy('position', 'asc'),
      ),
      defaultListenerOptions,
    )

    useEffect(() => {
      dispatch({ 
        products: inventory?.docs?.map((doc: any) => doc as DocumentSnapshot).sort((a: DocumentSnapshot, b: DocumentSnapshot) => (a.get('position') || 0) - (b.get('position') || 0)) 
      });
    },[inventory]);

    const checkItem = (id = '', checked = false) => {
      const selected = Array.from(checkedItems || []);
      if (checked) {
        if (!selected.includes(id)) {
          selected.push(id);
        }
      } else {
        if (selected.includes(id)) {
          selected.splice(selected.indexOf(id), 1);
        }
      }
      dispatch({ checkedItems: selected });
    };

    const deleteSelectedItems = async () => {
      if (!checkedItems.length) return;
      try {
        const batch = getBatch!();
        for (const id of checkedItems) {
            batch.update(inventoryItemRef!(organization?.id, id), { deletedAt: serverTimestamp() });
        }
        await batch.commit();
        flash.success('Selected items deleted.');
      } catch (err: any) {
        console.log(err.message);
        flash.error('Error deleting selected items');
      }
    };

    const checkAllItems = () => {
      let items: string[] | undefined = [];
      if (checkedItems.length < (products?.length || 0)) {
        // check all
        items = products?.map(({id}:DocumentSnapshot) => id);
      }
      dispatch({checkedItems: items});
    };

    const filterProducts = (): DocumentSnapshot[] => {
        if (!filter || filter === '' || filter.length < 2) {
          return products as DocumentSnapshot[];
        }
        const re = new RegExp(escapeRegExp(filter), 'gi');
        return products?.length 
          ? products?.filter((doc: DocumentSnapshot) => re.test(doc.get('name')) || re.test(doc.get('sku')) || re.test(doc.get('lot')) || re.test(doc.get('description')))
          : [];
      };
  
      const onSortEnd = async ({oldIndex = 0, newIndex = 0}) => {
        if (oldIndex === newIndex) return;
        const item = products?.[oldIndex];
        if (!item) return;
        debug('Got item to move', item?.get('name'), item?.get('position'), 'and move from', oldIndex, 'to', newIndex);
        const batch = getBatch!();
        if (newIndex > item?.get('position')) {
          products.forEach((p: DocumentSnapshot) => {
            if (
              p.id !== item.id &&
              p.get('position') >= item.get('position') &&
              p.get('position') <= newIndex && 
              p.get('position') > 0
            ) {
                batch.update(inventoryItemRef!(organization?.id, p.id), {
                    position: increment(-1),
                });
            }
          });
        } else if (newIndex < item.get('position')) {
          products.forEach((p: DocumentSnapshot) => {
            if (
              p.id !== item.id &&
              p.get('position') >= newIndex &&
              p.get('position') <= item.get('position')
            ) {
                batch.update(inventoryItemRef!(organization?.id, p.id), {
                    position: increment(1),
                });
            }
          });
        }
        batch.update(inventoryItemRef!(organization?.id, item.id), {
            position: newIndex,
        });
        try {
          dispatch({ loading: true });
          await batch.commit();
        } catch (err: any) {
          console.log(err.message);
          flash.error(`Error resorting products: ${err.message}`);
        } finally {
          dispatch({ loading: false });
        }
    };

    const items = filterProducts()?.map(item => item) || [];

    const handleDragEnd = async (event: DragEndEvent) => {

      const {active, over} = event;
      
      if (over?.id && active.id !== over.id) {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        if (oldIndex === newIndex || newIndex < 0 || oldIndex < 0) return products || [];
        await onSortEnd({ oldIndex, newIndex });
        return arrayMove(items, oldIndex, newIndex);
      }
    }

    return (
      <>
        <Box mt={2} mb={2} display='flex' justifyContent='flex-end'>
          { !disabled && (
            <Stack direction="row" spacing={1} divider={<Divider flexItem orientation='vertical'/>}>
            {channel?.type !== 'shopify' && (
              <>
                <EditItemModal 
                  disabled={(products?.length || 0) >= maxItems}
                  trigger={<Button startIcon={<AddIcon/>}>New</Button>}
                  source={source}
                />
                <Uploader
                  header="Upload Inventory File"
                  content="Select an Excel or CSV file to upload inventory for this event."
                  trigger={
                    <PrimaryButton variant="text" startIcon={<CloudUploadOutlinedIcon/>}>
                      Import
                    </PrimaryButton>
                  }
                  dest={{
                    path: `${source.type || 'store'}/${source.id}/${firebaseUser?.uid}`,
                    name: source.id,
                  }}
                  onSuccess={() => {
                    window.scrollTo(0, 0);
                    flash.success(
                      'Your file was successfully uploaded and will be processed shortly.'
                    );
                  }}
                  onError={() => {
                    window.scrollTo(0, 0);
                    flash.error(
                      'There was an error processing the upload.  Please make sure all of the required fields are present for each entry.'
                    );
                  }}
                />
                <Button
                  color="secondary"
                  startIcon={<CloudDownloadOutlinedIcon/>}
                  component="a"
                  href={`${process.env.REACT_APP_CDN}/public/kwixl_upload_template.xlsx`}
                >
                  Template
                </Button>
              </>
            )} 
            {channel?.type === 'shopify' && 
              <ShopifyProductImportModal source={source}/>
            }
            </Stack>
          )}
        </Box>
        <Box mt={1} mb={2}>
          <TextField
            fullWidth
            name="filter"
            value={filter}
            placeholder="Filter or search for items..."
            onChange={(e:ChangeEvent<HTMLInputElement>) => dispatch({filter: e.target.value})}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box display='flex'>
          <Box>
            <Stack direction="row" spacing={2} mt={1} mb={3} divider={<Divider flexItem orientation='vertical'/>}>
              <Button onClick={checkAllItems}>
                {!!products?.length && (checkedItems.length < (products?.length || 0) ? 'Select All' : 'Deselect All')}
              </Button>
              {!!checkedItems?.length && (
                <ConfirmModal
                  trigger={<Button startIcon={<DeleteForeverIcon/>}>Delete {!mobile && 'Selected'}</Button>}
                  title="Delete Selected Inventory Items?"
                  content="This will delete ALL selected inventory items. Are you sure?"
                  onConfirm={deleteSelectedItems}
                />
              )}
            </Stack>
          </Box>
          <Box flexGrow={1}/>
          <Box>
            <Stack direction="row" spacing={3} fontSize={mobile ? 'small' : 'inherit'}>
              <ItemTypeIcon type={'claim'} fontSize={mobile ? 'small' : 'inherit'}/> = Claim{' '}
              <ItemTypeIcon type={'bid'} fontSize={mobile ? 'small' : 'inherit'}/> = Bid{' '}
              <ItemTypeIcon type={'sale'} fontSize={mobile ? 'small' : 'inherit'}/> = Buy-It-Now{' '}
            </Stack>
          </Box>
        </Box>
        {!products?.length && (
          <AlertBox severity="info">
            There are currently no products. {!disabled && <>Use the "New" button above to add products.</>}
          </AlertBox>
        )}
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={items}>
            {items.map(item => (
              <SortableItem 
                key={item.id} 
                item={item} 
                source={source}
                filtered={!!filter} 
                selected={checkedItems.includes(item.id)}
                onSelect={checkItem} />
            ))}
          </SortableContext>
        </DndContext>
      </>
    );
}




