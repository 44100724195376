import { useReducer, useEffect, ChangeEvent, cloneElement, type JSX } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import omit from 'lodash/omit';
import {reducer, validate, flash, formatCurrency} from 'lib';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {
    RequiredField, 
    Money, 
    GenericObject,
    CancelButton,
    CheckButton,
} from 'components';
import {
    getDoc,
    addDoc,
    DocumentSnapshot,
} from 'firebase/firestore';
import styled from '@emotion/styled';
import Joi from 'joi';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import UndoIcon from '@mui/icons-material/Undo';

const schema = Joi.object({
    amount: Joi.number().min(1).messages({
        "number.empty": "Amount cannot be blank",
        "string.min": "Amount should have a minimum value of {#limit}",
        "any.required": "Amount is a required field",
    }),
    reason: Joi.string().required().min(5).max(100).messages({
        "string.empty": "Reason cannot be blank",
        "string.min": "Reason should have a minimum length of {#limit} characters",
        "string.max": "Reason should have a maximum length of {#limit} characters",
        "any.required": "Reason is a required field",
      })
});

interface Props {
    trigger?: JSX.Element;
    type: string;
    source: string;
    title?: string;
    open?: boolean;
    all?: boolean;
    total: number;
    onClose?: () => void;
}

const initialState = {
    invoice: null,
    isOpen: false,
    formErrors: {},
    type: null,
    source: null,
    max_amount: 0,
    amount: 0,
    reason: 'Customer requested',
}

export const RefundModal = ({
    trigger =   <Button startIcon={<UndoIcon/>}>
                    Refund
                </Button>,
    type,
    source, 
    open = false,
    all = false,
    title,
    total = 0,
    onClose = () => {},
}: Props) => {

    const {
        firebaseUser, 
    } = useFirebaseContext();

    const {organization} = useSessionContext();
    
    const {
        purchaseRef,
        invoiceRef, 
        refundsRef,
    } = useRefs();

    const [{
        invoice,
        isOpen,
        formErrors,
        amount, 
        max_amount,
        reason,
    }, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ isOpen: open });
    }, [open]);

    useEffect(() => {
        (async () => {
            if (!type || !source) return;
            let p: DocumentSnapshot;
            let i: DocumentSnapshot;
            let max_total = 0;
            switch (type) {
                case 'purchase':
                    p = await getDoc(purchaseRef!(source || 'x'));
                    max_total = p.get('price') * p.get('qty');
                    i = await getDoc(invoiceRef!(p.get('invoiceId') || 'x'))
                    break;
                case 'invoice':
                    i = await getDoc(invoiceRef!(source || 'x'))
                    break;
            }
            max_total = (i!.get('items') || [])
                .reduce((t: number, next: GenericObject) => t += parseFloat(next.price || 0) * next.qty || 1, 
            0);
            max_total += (i!.get('tax') || 0 + i!.get('shipping') || 0);
            dispatch({ 
                invoice: i!,
                max_amount: max_total,
                amount: max_total,
            });
        })()
    }, [source, type]);

    const handleClose = (cancel = true) => {
        onClose();
        dispatch({ isOpen: false });
    };

    const handleChange = ({target: {name, value} }: ChangeEvent<HTMLInputElement>) => {
        dispatch({ 
            [name]: value,
            formErrors: omit(formErrors, name),
        });
    };

    const handleMoneyChange = ({target: {name, value} }: ChangeEvent<HTMLInputElement>) => {
        dispatch({ 
            [name]: formatCurrency(value),
            formErrors: omit(formErrors, name),
        });
    };

    const handleSave = async () => {
        const errors = validate({
            amount,
            reason,
        }, schema);
        if (Object.keys(errors || {}).length > 0) {
            dispatch({ formErrors: errors });
            return;
        }
        if (amount > max_amount) {
            dispatch({ 
                formErrors: {
                    amount: 'Amount cannot be greater than the max refund amount'
                }
            })
            return;
        }

        try {
            await addDoc(
                refundsRef!, {
                    type,
                    source,
                    amount,
                    reason,
                    requestedBy: firebaseUser?.uid,
                    orgId: organization?.id,
                });
            flash.success('Refund has been submitted');
            handleClose();
        } catch (err: any) {
            flash.error(err.message);
        }
    }

    const Trigger = () => cloneElement(trigger, { disabled: isOpen, onClick: () => dispatch({ isOpen: true })});
    return (
        <>
            <Trigger/>
            <Dialog
                open={isOpen}
                onClose={() => handleClose()}
                maxWidth='md'
            >
                <DialogTitle>{title || 'Refund'}</DialogTitle>
                <DialogContent dividers>
                    <Box mb={2}>
                        Refunds may take several minutes to process and may not become available to the customer for a week or more depending upon the processing bank.
                    </Box>
                    <Box mb={3}>
                        <Stack direction="column" spacing={1}>
                            <Box>
                                <Title>
                                    Sub-Total:
                                </Title>
                                <Value>
                                    <Money value={invoice?.get('total') || 0} basic={true}/>
                                </Value>
                            </Box>
                            <Box>
                                <Title>
                                    Shipping:
                                </Title>
                                <Value>
                                    <Money value={invoice?.get('shipping') || 0} basic={true}/>
                                </Value>
                            </Box>
                            <Box>
                                <Title>
                                    Tax:
                                </Title>
                                <Value>
                                    <Money value={invoice?.get('tax') || 0} basic={true}/>
                                </Value>
                            </Box>
                            { type === 'invoice' && (
                                <Box>
                                    <Title>
                                        {all ? 'Total' : 'Max'} Refund:
                                    </Title>
                                    <Value>
                                        <Money value={max_amount || 0}/>
                                    </Value>
                                </Box>
                            )}
                            { type === 'purchase' && (
                                <Box>
                                    <Title>
                                        Refund:
                                    </Title>
                                    <Value>
                                        <Money value={max_amount || 0}/>
                                    </Value>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                    { (type === 'invoice' && !all) && (
                        <Box mt={2}>
                            <RequiredField
                                error={!!formErrors['amount']}
                                message={formErrors['amount']}
                            >
                                <FormControl error={Object.keys(formErrors).length > 0} fullWidth>
                                    <TextField
                                        label="Refund Amount"
                                        name="amount"
                                        value={amount}
                                        onChange={handleMoneyChange}
                                    />
                                </FormControl>
                            </RequiredField>
                        </Box>
                    )}
                    <Box mt={2} mb={2}>
                        <RequiredField
                            error={!!formErrors['reason']}
                            message={formErrors['reason']}
                        >
                                <FormControl error={Object.keys(formErrors).length > 0} fullWidth>
                                    <TextField 
                                        label="Reason"
                                        name="reason" 
                                        value={reason} 
                                        onChange={handleChange} 
                                    />
                                </FormControl>
                        </RequiredField>
                    </Box>
                </DialogContent>
                <DialogActions style={{textAlign: 'center'}}>
                    <CancelButton onClick={() => handleClose()}/>
                    <CheckButton onClick={() => handleSave()}>
                        Apply Refund
                    </CheckButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Title = styled.span`
    display: inline-block;
    font-weight: bolder;
    width: 50%;
    max-width: 100px;
`

const Value = styled.span`
    display: inline-block;
    width: 50%;
    max-width: 115px;
    text-align: right;
`