import Joi from "joi";

export const registration_schema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: false })
    .required()
    .messages({
      "string.email": `"Email" must be a valid email address`,
    }),
  /*
  handle: Joi.string().min(5).max(25).required().messages({
    "string.min": `"User Handle" should have a minimum length of {#limit} characters`,
    "string.max": `"User Handle" should have a maximum length of {#limit} characters`,
    "any.required": `"User Handle" is a required field`,
  }),
  */
  firstName: Joi.string().min(1).required(),
  lastName: Joi.string().min(1).required(),
  password: Joi.string().min(8).max(25).required().messages({
    "string.min": `"Password" should have a minimum length of {#limit} characters`,
    "string.max": `"Password" should have a maximum length of {#limit} characters`,
    "any.required": `"Password" is a required field`,
  }),
  password2: Joi.string()
    .min(8)
    .max(25)
    .valid(Joi.ref("password"))
    .required()
    .messages({
      "string.min": `"Confirmation password" should have a minimum length of {#limit} characters`,
      "string.max": `"Confirmation password" should have a maximum length of {#limit} characters`,
      "any.required": `"Confirmation password" is a required field and must match the Password field`,
      "any.only": "The password and confirmation passwords do not match",
    }),
  agree: Joi.any().valid(1, true).required().messages({
    "any.only":
      "Agreement to the Terms and Conditions and Privacy Policy is required to register",
  }),
});
