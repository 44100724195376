import { GenericObject } from "components"
export const plans: GenericObject [] = [
    {
        id: 'free',
        name: 'FREE',
        price: 0,
        contacts: 1000,
        items: [
            '1 Facebook and 1 Instagram account',
            '2 (two) automations per account'
        ],
        priceDescription: '', 
        color: '#0EAD9E',
        extra: 'No credit card required!',
    },
    {
        id: 'pro',
        name: 'Pro',
        price: 9.99,
        contacts: 2500,
        items: [
            'Up to 10 social accounts',
            'Unlimited automations'
        ],
        priceDescription: 'starting at',
        color: '#0E1EAD',
    },
    {
        id: 'business',
        name: 'Business',
        price: 19.99,
        contacts: 5000,
        items: [
            'Unlimited social accounts',
            'Unlimited automations'
        ],
        priceDescription: 'starting at',
        color: 'primary.main',
    }
]