import {DateTime} from 'luxon';
import {GenericObject} from 'components';

type DateObject = {
  seconds: number;
};

type DateTypes = Date | DateObject | DateTime;

interface Props {
  date: DateTypes;
  format?: GenericObject | string;
}

// functions

export const dt = (date: DateTypes) => {
  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }
  return date instanceof Date
    ? DateTime.fromJSDate(date)
    : date instanceof DateTime
    ? date
    : DateTime.fromMillis(date.seconds * 1000);
};

// https://moment.github.io/luxon/#/parsing?id=table-of-tokens
export const formattedDate = (date: DateTypes, format: GenericObject | string) => 
  date 
    ? typeof format === 'string'
      ? dt(date)?.toFormat(format)
      : dt(date)?.toLocaleString(format) 
    : null;

// MM/DD/YYYY
export const shortDate = (date: DateTypes) =>
  formattedDate(date, 'M/d/yy');

// December 1, 2020
export const fullDate = (date: DateTypes) =>
  formattedDate(date, DateTime.DATETIME_FULL);

// Oct 14, 1983, 9:30 AM
export const mediumDate = (date: DateTypes) =>
  formattedDate(date, DateTime.DATETIME_MED);

export const millis = (date: DateTypes) => dt(date).toMillis();

export const now = DateTime.utc();

// 09:30:23 AM EDT
export const offsetTime = (date: DateTypes) =>
  formattedDate(date, DateTime.TIME_WITH_SHORT_OFFSET);

// 09:30:23 AM  
export const timeWithSeconds = (date: DateTypes) =>
  formattedDate(date, DateTime.TIME_WITH_SECONDS);

// components

export const FullDate = ({date}: Props) => <>{fullDate(date)}</>;

export const ShortDate = ({date}: Props) => <>{shortDate(date)}</>;

export const MediumDate = ({date}: Props) => <>{mediumDate(date)}</>;

export const OffsetTime = ({date}: Props) => <>{offsetTime(date)}</>;

export const TimeWithSeconds = ({date}: Props) => <>{timeWithSeconds(date)}</>;

export const FormattedDate = ({date, format = DateTime.DATETIME_FULL}: Props) => <>{formattedDate(date, format)}</>
