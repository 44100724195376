import {useEffect, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Loading,
  PrimaryButton,
  GenericObject,
  IntlText,
  FacebookText,
} from 'components';
import {reducer, flash} from 'lib';
import {useFirebaseContext, useFacebookContext, useSessionContext, useRefs} from 'hooks';
import {EventType} from '@kwixl/interface';
import {DateTime} from 'luxon';
import {ROUTES} from 'routes';
import {setDoc} from 'firebase/firestore';
import FacebookIcon from '@mui/icons-material/Facebook';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { VideoSourceStep } from './components/VideoSourceStep';
import { SelectPage } from './components/SelectPage';
import { SelectLiveVideo } from './components/SelectLiveVideo';
import { EventDetails } from './components/EventDetails';
import { Modal } from 'modals';
import Typography from '@mui/material/Typography';

enum STAGE {
  NONE = 'none',
  LINK = 'link',
  TYPE = 'type',
  METHOD = 'method',
  EVENT = 'event',
  SELECT = 'select',
  SEARCH = 'search',
  LIST = 'list',
  VIDEO = 'video',
  INVENTORY = 'inventory',
  FINISHED = 'finished',
  ERROR = 'error',
}

let steps: string[] = [
  'Video Source',
  'Select Page',
  'Complete',
];

const initialState: GenericObject = {
  ready: false,
  stage: STAGE.METHOD,
  type: null,
  event: {
    name: '',
    description: '',
    ticketed: false,
    startAt: DateTime.now().plus({minute: 1}).toJSDate(),
    endAt: DateTime.now().plus({hours: 4, minute: 1}).toJSDate(),
    type: EventType.ccs,
  },
  errors: {},
  stream: null,
  lots: [],
  newItem: {},
  formErrors: {},
  loading: false,
  groups: null,
  userID: null,
  accessToken: null,
  group_index: null,
  videos: null,
  target: null,
  targetId: null,
  open: false,
  videoId: '',
  message: <></>,
  page: {},
};

declare global {
  interface Window {
    FB: any;
  }
}

export const Facebook = (props: any) => {
  const {
    eventId,
    onSuccess,
  } = props;

  const {
    filterEmbedHtml,
    getFacebookPages,
    fbLongLivedToken,
  } = useFacebookContext();

  const {
    callable, 
  } = useFirebaseContext();

  const {organizationFacebookPageRef} = useRefs();

  const { mobile, organization } = useSessionContext();

  const [
    {
      stage,
      event,
      stream,
      lots,
      loading,
      userID,
      activeStep = 0,
      page,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const navigate = useNavigate();

  useEffect(() => {
    switch (stage) {
      case STAGE.FINISHED:
        createEvent();
        break;
    }
  }, [stage]);

  const preloadPages = async () => {
    const {userID = '', accessToken = '' } = await getFacebookPages!() || {};
    dispatch({
      ready: true,
      streamKey: null,
      userID,
      userToken: accessToken,
      accessToken,
      stage: STAGE.METHOD,
    });
  };

  const createEvent = async () => {
    dispatch({loading: true});
    const regex = /(<([^>]+)>)/gi;
    try {
      const {data, errors} = await callable!('facebook-ccs', {
        eventId,
        name: event?.name,
        category: event?.category || null,
        description: event?.description?.replace(regex, ''),
        stream: {
          ...stream,
          userID,
          //token: userToken,
        },
        lots,
      });
      if (!data || errors) {
        throw Error('Invalid data');
      }
      // Re-up authorization on subscribed page 
      if (page?.id) {
        await setDoc(
          organizationFacebookPageRef!(organization?.id || 'x', page.id || 'x'),
          { 
            status: 'requested',
            page_token: page.access_token,
            access_token: fbLongLivedToken,
          }, {
            merge: true
          });
      }
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data);
      } else if (!eventId) {
        navigate(ROUTES.cp_kwixllive_event_live(data.id));
      }
    } catch (err: any) {
      flash.error(`Error creating event: ${err.message}`);
      dispatch({ stage: STAGE.ERROR });
    } finally {
      //handleClose();
      dispatch({ loading: false });
    }
  };

  const handleClose = () => {
    dispatch({ ...initialState, activeStep: 0, page: null, confirm: false });
  };

  /*
  const handleLotChange = (e: any) => {
    if (e.target.name.indexOf('_') > -1) {
    } else {
      try {
        delete formErrors[e.target.name];
      } catch (err) {}
      dispatch({formErrors, newItem: {...newItem, [e.target.name]: e.target.value}});
    }
  };

  const addLot = () => {
    let valid = newItemValid();
    let msg = 'Invalid item';
    if (valid) {
      const index = lots.findIndex(({lot}:{lot: number}) => lot === newItem.lot);
      if (index >= 0) {
        valid = false;
        msg = `Lot ${newItem.lot} already exists.`;
        dispatch({formErrors: {lot: msg}});
      }
    }
    if (!valid) {
      flash.error(msg);
      return;
    }
    lots.unshift(newItem);
    dispatch({
      formErrors: {},
      lots,
      newItem: {lot: '', item: '', price: '', qty: 1},
    });
  };

  const newItemValid = () => {
    const errors = validate(newItem, lineitem_schema);
    dispatch({formErrors: errors || {}});
    return !errors;
  };

  const getInventory = () => {
    return (
      <div>
          <Grid columns={5}>
            <p>
              This step is optional. If you enter inventory here, the system
              will be able to automatically validate claims received (i.e.
              price, qty). If you skip this step, the system will record all
              claims and present them to you in the event admin screen ordered
              by first received. You will then need to manually select the
              winning claim(s).
            </p>
            <Grid item>
              <Grid item>
                <TextField
                  error={formErrors?.hasOwnProperty('lot')}
                  name={`lot`}
                  placeholder="Lot"
                  onChange={handleLotChange}
                  value={newItem?.lot}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={formErrors?.hasOwnProperty('item')}
                  name={`item`}
                  placeholder="Item name"
                  onChange={handleLotChange}
                  value={newItem?.item}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={formErrors?.hasOwnProperty('qty')}
                  name={`qty`}
                  placeholder="Qty"
                  onChange={handleLotChange}
                  value={newItem?.qty}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={formErrors?.hasOwnProperty('price')}
                  name={`price`}
                  placeholder="Price"
                  onChange={handleLotChange}
                  value={newItem?.price}
                />
              </Grid>
              <Grid item>
                <PrimaryButton onClick={() => addLot()}>
                  <AddIcon/>
                </PrimaryButton>
              </Grid>
            </Grid>
            {lots && lots.length > 0 && <Divider />}
            {lots.map(({lot = '', item = '', qty = 0, price = 0}, index: number) => (
              <Grid item>
                <Grid item>
                  <TextField
                    name={`lot_${index}`}
                    onChange={handleLotChange}
                    value={lot}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name={`item_${index}`}
                    onChange={handleLotChange}
                    value={item}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name={`qty_${index}`}
                    onChange={handleLotChange}
                    value={qty}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name={`price_${index}`}
                    onChange={handleLotChange}
                    value={price}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            ))}
          </Grid>
      </div>
    );
  };
  */

  return (
    <Modal
      closeMessage={stage !== STAGE.FINISHED ? 'Any information entered will not be saved. Are you sure?' : ''}
      trigger={props?.trigger || (
        <PrimaryButton 
          size={ mobile ? 'small' : 'medium' }
          startIcon={<FacebookIcon/>} 
        >
            <IntlText id="button_ccs" />
        </PrimaryButton>
      )}
      fullWidth
      maxWidth="md"
      opened={() => preloadPages()}
      closed={() => handleClose()}
    >
      <DialogTitle textAlign="center">
        <Box mb={2}>
          Live CCS Event
        </Box>
       {stage !== STAGE.METHOD && (
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map(step => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
       )}
      </DialogTitle>
        {(!stage || stage === STAGE.NONE || loading) && (
          <>
            <DialogContent dividers>
              <Loading/>
            </DialogContent>
          </>
        )}
        { stage === STAGE.METHOD && (
            <VideoSourceStep 
              onSelect={(type: string) => {
                if (type === 'live') {
                  steps = steps.slice(0, 2).concat('Event Details', 'Complete');
                  dispatch({
                    stage: STAGE.VIDEO,
                    stream: {
                      ...stream,
                      type: 'live',
                    },
                    activeStep: 1,
                  })
                } else {
                  steps = steps.slice(0, 2).concat('Select Video', 'Event Details', 'Complete');
                  dispatch({
                    streamKey: null,
                    stage: STAGE.SELECT,
                    stream: {
                      ...stream,
                      type: 'embed',
                    },
                    activeStep: 1,
                  });
                }
              }}
            />
        )}
        { stage === STAGE.VIDEO && (
          <SelectPage 
            value={page?.id}
            onBack={() => dispatch({ stage: STAGE.METHOD, activeStep: 0 })}
            onNext={(p) => {
              dispatch({
                page: p,
                target: 'page',
                stream: {
                  ...stream,
                  target: 'page',
                  targetId: p?.id,
                  type: 'live',
                  token: p?.access_token,
                },
                stage: STAGE.EVENT,
                activeStep: 2,
              })
            }}
          />
        )}
        {stage === STAGE.SELECT && (
          <SelectPage 
            live
            value={page}
            onNext={(p => {
              dispatch({
                page: p,
                stage: STAGE.LIST, 
                activeStep: 2,
              })
            })}
            onBack={() => dispatch({ stage: STAGE.METHOD, activeStep: 0 })}
          />
        )}
        {stage === STAGE.LIST && (
          <SelectLiveVideo 
            page={page} 
            onNext={(video: GenericObject) => {
              if (stream?.type === 'embed') {
                dispatch({
                  //eventName: video.title || `Event #${eventId}`,
                  stream: {
                    streamId: video?.id,
                    token: page?.access_token,
                    url: filterEmbedHtml!(video?.embed_html || ''),
                    embedHtml: video?.embed_html || '',
                    source: video,
                    target: 'page',
                    targetId: page?.id,
                    type: 'embed',
                  },
                  stage: STAGE.EVENT,
                  activeStep: 3,
                  event: { 
                    ...event,
                    name: video?.title,
                    startAt: DateTime.utc().toJSDate(),
                  }
                });
              } else {
                dispatch({stage: STAGE.EVENT});
              }
            }} 
            onBack={() => dispatch({ stage: STAGE.SELECT, activeStep: 1 })}
          />
        )}
        {stage === STAGE.EVENT && (
          <EventDetails
            data={event}
            stream={stream}
            onBack={() => dispatch({ 
              stage: stream?.type === 'embed' 
                ? STAGE.SELECT 
                : STAGE.VIDEO,
              activeStep: 1,
            })}
            onNext={(e) => dispatch({
              event: e,
              stage: STAGE.FINISHED,
              activeStep: steps.length -1,
            })}
            ccs={true}
          />
        )}
        {stage === STAGE.ERROR && (
          <Box padding='2rem' textAlign='center'>
            <Typography paragraph color='red' fontWeight='bold'>
              There was an error creating your event.
            </Typography>
            <Typography paragraph>
              Please check to make sure your <FacebookText/> page meets the requirements for live video.<br/>Your page must have at least 100 followers in order to go live.
            </Typography>
          </Box>
        )}
        {(stage === STAGE.FINISHED && !loading) && (
          <Box padding="2rem" textAlign="center">
            <Typography paragraph>
              Your {stream?.type === 'embed' ? 'event' : 'video'} is ready!  
            </Typography>
            {stream?.type !== 'embed' && (
              <Typography paragraph>
                You can now close this window and start your video by clicking the red icon in your video panel.
              </Typography>
            )}
          </Box>
        )}
        {(() => {
          switch (stage) {
            case STAGE.INVENTORY:
              return 'Add Inventory (Optional)';
          }
        })()}
    </Modal>
  )
};

