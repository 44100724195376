import {ChangeEvent, useEffect, useReducer} from 'react';
import {GenericObject} from 'components';
import {countries, states, reducer} from 'lib';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

export interface AddressData {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface Props {
  initial?: {[key: string]: any};
  onChange?: (update: AddressData, errors: GenericObject) => void;
  errors?: GenericObject;
  lockCountry?: boolean;
}

export const AddressForm = (props: Props) => {

  const {
    initial = {country: 'US'},
    onChange = () => {},
    lockCountry = false,
    errors = {},
  } = props;

  const [{
    address1 = '', 
    address2 = '', 
    city = '', 
    state = '', 
    zip = '', 
    country = 'US',
  }, dispatch] =
    useReducer(reducer, initial);

  useEffect(() => {
    const address = {
      address1,
      address2,
      city,
      state,
      zip,
      country: country || 'US',
    };
    onChange(address, errors);
  }, [address1, address2, city, state, zip, country]);

  const lookupAddress = async () => {
    if ((city && state) || !zip) return;
    try {
      const lookup = await (
        await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            zip
          )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        )
      ).json();
      const result: GenericObject = {};
      lookup.results[0].address_components.forEach((row: any) => {
        if (row.types.indexOf('locality') > -1) {
          result.city = row.long_name;
        } else if (row.types.indexOf('administrative_area_level_2') > -1) {
          result.county = row.short_name;
        } else if (row.types.indexOf('administrative_area_level_1') > -1) {
          result.state = row.short_name;
        } else if (row.types.indexOf('country') > -1) {
          result.country = row.short_name;
        }
      });
      if (Object.keys(result).length > 0) {
        delete errors.city;
        delete errors.state;
        delete errors.country;
        const newCity = city || result.city;
        const newState = state || result.state;
        const newCountry = country || result.country;
        dispatch({
          city: newCity,
          state: newState,
          country: newCountry,
        });
      }
    } catch (err) {
      return;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => { 
    const {name, value} = e.target;
    delete errors[name];
    dispatch({
      [name]: value,
    });
  };

  return (
    <Box mt={1}>
      <Box mt={1} mb={2}>
        <FormControl fullWidth error={errors?.country ? true : false}>
          <InputLabel>Country</InputLabel>
          <Select
            label="Country"
            name="country"
            //placeholder="Select your country"
            value={country || 'US'}
            disabled={lockCountry}
            onChange={(e: SelectChangeEvent) => handleChange(e as ChangeEvent<HTMLInputElement>)}
          >
            {Object.values(countries).map(value => (
              <MenuItem value={value.value}>{value.text}</MenuItem>
            ))}
          </Select>
          {errors?.country && (
            <FormHelperText error>
              Country is a required entry.
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box mt={1} mb={2}>
        <FormControl fullWidth>
          <TextField
            label="Zip/Postal Code"
            name="zip"
            value={zip}
            placeholder="Zip/Postal Code"
            onChange={handleChange}
            onBlur={lookupAddress}
            error={errors?.hasOwnProperty('zip')}
            helperText={errors?.zip ? 'Zip/Postal Code is a required entry.' : ''}
          />
        </FormControl>
      </Box>
      <Box mt={1} mb={2}>
        <FormControl fullWidth error={errors.hasOwnProperty('address1')}>
          <TextField
            label="Address"
            name="address1"
            value={address1 || ''}
            placeholder="Street address"
            onChange={handleChange}
            error={errors?.hasOwnProperty('address1')}
            helperText={errors?.address1 ? 'Address is a required entry.' : ''}
          />
        </FormControl>
      </Box>
      <Box mt={1} mb={2}>
        <FormControl fullWidth>
          <TextField
            name="address2"
            value={address2 || ''}
            placeholder="Secondary street address"
            onChange={handleChange}
            error={errors?.hasOwnProperty('address2')}
            helperText={errors?.address2 ? 'Invalid second address line.' : ''}
          />
        </FormControl>
      </Box>
      <Box mt={1} mb={2}>
          <FormControl fullWidth>
            <TextField
              label="City/Province"
              name="city"
              value={city}
              placeholder="City/province"
              onChange={handleChange}
              error={errors?.hasOwnProperty('city')}
              helperText={errors?.city ? 'City is a required entry.' : ''}
            />
          </FormControl>
      </Box>
      <Box mt={1} mb={2}>
          {!country || country === 'US' ? (
            <FormControl fullWidth error={errors?.state ? true : false}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                name="state"
                //placeholder="Select State"
                value={state}
                onChange={(e: SelectChangeEvent) => handleChange(e as ChangeEvent<HTMLInputElement>)}
                error={errors.hasOwnProperty('state')}
              >
                {Object.values(states).map((value) => (
                  <MenuItem value={value.value}>{value.text}</MenuItem>
                ))}
              </Select>
              {errors?.state && (
                <FormHelperText error>
                  State is a required entry.
                </FormHelperText>
              )}
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <TextField
                label="State/Region"
                name="state"
                value={state}
                placeholder="State/Region"
                onChange={handleChange}
                error={errors.hasOwnProperty('state')}
                helperText={errors?.state ? 'State is a required entry.' : ''}
              />
            </FormControl>
          )}
      </Box>
    </Box>
  );
};
