import {ChangeEvent, useEffect, useReducer} from 'react';
import {reducer, flash} from 'lib';
import {CdnImage, CancelButton, PrimaryButton, Loading, Money} from 'components';
import { ConfirmModal } from 'modals';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {
    doc, 
    serverTimestamp,
    getDocs,
    query,
    where,
    limit,
    orderBy,
} from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const initialState = {
    open: false,
    loading: false,
    message: '',
    collectionId: null,
    products: [],
    collections: [],
    selected: [],
}

/* TODO: Type for source */
export const ShopifyProductImportModal = ({ source }:{ source: any }) => {

    const [{
        open,
        loading,
        products,
        collections,
        selected,
        collectionId,
        message,
    }, dispatch] = useReducer(reducer, initialState);

    const {
        callable,
        getBatch,
    } = useFirebaseContext();

    const {
        mobile,
        organization
    } = useSessionContext();

    const {
        inventoryItemRef,
        inventoryRef,
    } = useRefs();
    
    const buttonSize = mobile ? 'mini' : 'tiny';

    useEffect(() => {
        (async () => {
            if (!open || !organization) return;
            dispatch({
                loading: true,
                message: 'fetching collections...'
            });
            const { data } = await callable!('shopify-collections');
            dispatch({
                collections: data?.collections || [],
                loading: false,
            });
        })()
    },[open, organization]);

    const getProducts = async () => {
        dispatch({ 
            loading: true,
            message: 'fetching products...'
        });
        const { data } = await callable!('shopify-products', { collection: collectionId });
        if (!data?.products || data.error ) {
            flash.error(`There was an error loading products ${data.error ? `: ${data.error}` : '' }`);
            handleClose();
            return;
        }
        dispatch({
            products: data?.products || [],
            loading: false,
        });
    }

    const handleClose = () => {
        dispatch(initialState);
    }

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if (checked) {
            if (!selected.includes[name]) selected.push(name);
        } else {
            const i = selected.findIndex((s: string) => s === name);
            if (i >= 0) {
                selected.splice(i, 1);
            }
        }
        dispatch({ selected });
    }

    const importSelected = async () => {

        const filteredProducts = products.filter((p: any) => selected.includes(p.id));

        const batch = getBatch!();

        dispatch({ 
            loading: true,
            message: 'Importing selected products...'
        });

        let nextLotNumber = 1;
        const results = await getDocs(
            query(
                inventoryRef!(organization?.id), 
                where('source.id', '==', source.id), 
                orderBy('lot', 'desc'), 
                limit(1)
            )
        );
        if (results?.docs.length) {
            try {
                nextLotNumber = parseInt(results.docs[0].get('lot') || 0) + 1;
            } catch (err: any) {
            }
        }
        for (const product of filteredProducts) {
            const ref = doc(inventoryRef!(organization?.id || 'x'));
            batch.set(
                inventoryItemRef!(organization?.id || 'x', ref.id || 'x'),
                {
                    ...product,
                    lot: nextLotNumber,
                    orgId: organization?.id,
                    source: {
                        ...source,
                        from: 'shopify',
                    },
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    deletedAt: null,
                    type: 'claim',
                    published: true,
                }
            )
            nextLotNumber++;
        }
        await batch.commit();

        handleClose();

    }

    if (!open) return (
        <Button variant="contained" startIcon={<AddShoppingCartIcon/>} onClick={() => dispatch({ open: true })}>
            Import From Shopify 
        </Button>
    )
    return (
        <Dialog
            open={open}
            scroll={'paper'}
        >
            <DialogTitle>
                Import From Shopify
            </DialogTitle>
            {collections?.length > 0 && (
                <DialogContent>
                    <Select
                        //placeholder='All Products'
                        onChange={(e: SelectChangeEvent) => dispatch({ collectionId : e.target.value })}
                    > 
                        {[{
                            id: undefined,
                            text: 'All Products',
                            value: undefined,
                        }].concat(collections.map((c: any) => (
                            {
                                key: c.id,
                                text: c.name,
                                value: c.id
                            }
                        ))).map(c => (
                            <MenuItem value={c.id}>{c.text}</MenuItem>
                        ))}
                    </Select>
                    <PrimaryButton
                        startIcon={<ArrowCircleRightOutlinedIcon/>}
                        onClick={getProducts}
                        style={{marginLeft:'10px'}}
                    >
                        Fetch Products
                    </PrimaryButton>
                </DialogContent>
            )}
            <DialogContent>
            { loading 
                ?   <Loading>Please wait...{message}</Loading> 
                :   products?.length > 0 && (
                        <Grid container spacing={2}>
                            <Grid container item>
                                <Grid item>
                                    Product
                                </Grid>
                                <Grid item>
                                    Qty
                                </Grid>
                                <Grid item>
                                    Price
                                </Grid>
                                <Grid item>
                                    <Checkbox 
                                        checked={products.length > 0 && selected.length === products.length}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.checked) {
                                                dispatch({ selected: products.map((p: any) => p.id) });
                                            } else {
                                                dispatch({ selected: [] });
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            { products.map((product: any) => (
                                    <Grid container key={`${product.id}_${product.product_id}`}>
                                        <Grid item>
                                            <CdnImage size="mini" src={product?.image}/>
                                        </Grid>
                                        <Grid item>
                                            {product.name}
                                        </Grid>
                                        <Grid item>
                                            {product.qty}
                                        </Grid>
                                        <Grid item>
                                            <Money value={product.price}/>
                                        </Grid>
                                        <Grid item>
                                            <Checkbox 
                                                name={product.id}
                                                checked={selected.includes(product.id)}
                                                onChange={handleCheck}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    )
            }
            </DialogContent>
            <DialogActions>
                {selected.length > 0
                    ?   <ConfirmModal
                            title={'Confirm Close'}
                            trigger={<span><CloseIcon/> Cancel</span>}
                            content={'You have selected products for import! Do you wish to exit?'}
                            onConfirm={handleClose}
                        />
                    :   <CancelButton onClick={handleClose}/>
                }
                <PrimaryButton 
                    startIcon={<CloudDownloadOutlinedIcon/>}
                    onClick={importSelected}
                    disabled={selected.length < 1}
                >
                    Import
                </PrimaryButton>
            </DialogActions>

        </Dialog>
    )
}