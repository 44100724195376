import { PropsWithChildren } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import {ROUTES} from 'routes';
import { checkMobile } from './Device';
import { InternalLink } from 'components';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

interface HeaderProps extends TypographyProps {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    compact?: boolean;
}

export const Header = ({ children, as = 'h1', ...rest }:PropsWithChildren & HeaderProps) => <Typography variant={as} component={as} { ...rest }>{children}</Typography>

export const PageTitle = ({ textAlign = 'left', compact = false, children }: TypographyProps & { compact?: boolean; }) => 
    <Typography fontWeight='bolder' textAlign={textAlign} component="h1" variant={checkMobile() ? "h5" : "h4"} mb={compact ? 0 : 3}>{children}</Typography>

export const ReturnHeader = ({ to, children, as, textAlign = 'left', ...rest }:PropsWithChildren & HeaderProps & { to?: string }) => (
    <Typography 
        textAlign={textAlign} 
        component={'h1'} 
        variant={as || checkMobile() ? 'h6' : 'h4'} 
        fontWeight='bolder'
        mb={3}
        { ...rest}
    >
        <InternalLink to={to || ROUTES.me}>
            <ArrowLeftIcon
                fontSize='large'
                sx={{
                    display: 'inline',
                    verticalAlign: 'middle',
                    marginRight: '10px',
                }}
            />
        </InternalLink>
        {children}
    </Typography>
);