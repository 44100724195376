import { FBIcon } from 'assets/facebook';
import { IGIcon } from 'assets/instagram';

export const PlatformIcon = (props: any) => {
    const { platform, ...rest } = props;
    switch (platform) {
        case 'instagram':
            return <IGIcon {...rest} />
        case 'facebook':
            return <FBIcon {...rest} />
    }
    return null;
}
export const platformIcon = (platform: string) => {
    switch (platform) {
        case 'instagram':
            return <IGIcon/>
        case 'facebook':
            return <FBIcon/>
    }
    return null;
}