import {toArray} from 'react-emoji-render';
//import {ReactElement, JSXElementConstructor} from 'react';

export const parseEmojis = (value: any) => {
  const emojisArray = toArray(value);
  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce(
    (previous: any, current: any) => {
      if (typeof current === 'string') {
        return previous + current;
      }
      return previous + current.props.children;
    },
    ''
  );
  return newValue;
};
