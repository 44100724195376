import { FC, ReactNode, useState, useEffect, type JSX } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ButtonPropsColorOverrides } from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import { CheckButton } from 'components';
import { Modal } from 'modals';
import Typography from '@mui/material/Typography';

interface Props {
    trigger?: JSX.Element;
    color?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    >,
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    content?: string | JSX.Element;
    open?: boolean;
    onClose?: () => void;
    onConfirm: () => void;
    disabled?: boolean;
    confirmButton?: string;
    compact?: boolean;
    children?: ReactNode;
}

export const ConfirmModal: FC<Props & Omit<DialogProps, 'open' | 'content' | 'title'>> = ({ 
    trigger,
    title = 'Confirm Action',
    subtitle,
    color = 'primary',
    content,
    open = false,
    onClose = () => {},
    onConfirm = () => {},
    disabled = false,
    confirmButton = 'Confirm',
    compact = false,
    children = null,
    maxWidth = "xs",
    ...rest
}) => {

    const [active, setActive] = useState<boolean>(open);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setActive(open);
    },[open]);

    const handleConfirm = () => {
        setLoading(true);
        handleClose();
        onConfirm();
    }

    const handleClose = () => {
        setLoading(false);
        setActive(false);
        onClose();
    }

    return (
        <Modal 
            open={active}
            trigger={trigger}
            opened={() => setActive(true)}
            closed={handleClose}
            scroll='paper'
            fullWidth={true}
            maxWidth={maxWidth}
            { ...rest }
        >
            <DialogTitle textAlign='center'>
                {title}
            </DialogTitle>
            { subtitle 
                ? typeof subtitle === 'string' 
                    ? <Typography variant='h6'>{subtitle}</Typography>
                    : <span>{subtitle}</span>
                : null
            }
            <DialogContent dividers>
                {content || children || 'Are you sure?'}
            </DialogContent>
            <DialogActions>
                <CheckButton startIcon={<CheckIcon/>} disabled={loading} loading={loading} onClick={handleConfirm}>{confirmButton}</CheckButton>
            </DialogActions>
        </Modal>
    )
}           