import { useReducer, useEffect, ChangeEvent, cloneElement, type JSX } from 'react';
import {reducer, flash, FBApi} from 'lib';
import {CancelButton, CheckButton, GenericObject, Loading} from 'components';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {
    updateDoc,
    serverTimestamp,
    getDoc,
    DocumentSnapshot,
    setDoc,
    addDoc,
} from 'firebase/firestore';
import {PurchaseTypes} from '@kwixl/interface';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    comment: DocumentSnapshot;
    trigger?: JSX.Element;
}

export const FacebookClaimModal = ({
    trigger = <IconButton>
                <AddIcon/>
            </IconButton>,
    comment,
}: Props) => {

    const [{ 
        open = false,
        claim,
        reply = '',
        ready = false,
        like = false,
    }, dispatch] = useReducer(reducer, {});

    const {
        firebaseUser,
    } = useFirebaseContext();

    const {organization} = useSessionContext();
    
    const {
        facebookCommentRef,
        facebookCommentRepliesRef,
        facebookPostRef,
        organizationFacebookPageRef,
    } = useRefs();

    useEffect(() => {
        (async () => {
            if (!comment) return;
            if (!comment.get('claim')) {
                // try to kick off backend process to parse comment...this should never be needed
                await updateDoc(facebookCommentRef!(organization?.id, comment.get('post.id'), comment.id), { updatedAt: serverTimestamp() });
            }
            const update: GenericObject = {
                claim: comment.get('claim') || {},
                ready: true
            }
            if (organization) {
                const post = await getDoc(facebookPostRef!(organization.id || 'x', comment.get('post.id') || 'x'));
                if (post.exists()) {
                    update.reply = post.get('settings.reply_message_accepted');
                }
            }
            dispatch(update);
        })()
    },[comment, organization]);

    const handleClose = () => {
        dispatch({ open: false, ready: false });
    }

    const save = async () => {
        try {
            await setDoc(
                comment.ref, 
                { 
                    claim: {
                        ...claim,
                        type: PurchaseTypes.claim,
                        status: 'accepted',
                    },
                },{
                    merge: true,
                });
            await addDoc(facebookCommentRepliesRef!(organization?.id || 'x', comment.get('post.id') || 'x', comment.id || 'x'), {
                message: reply,
                createdAt: serverTimestamp(),
                repliedTo: comment.id,
                uid: firebaseUser?.uid,
            });
            flash.success('Claim saved.');
            if (like) {
                const page = await getDoc(organizationFacebookPageRef!(organization?.id || 'x', comment.get('page.id') || 'x'));
                if (page.exists()) {
                    await FBApi(
                        `/${comment.id}/likes`, 
                        'POST', 
                        page.get('page_token')
                    )
                    .catch(err => flash.error(err.message));
                }
            }
            handleClose();
        } catch (err: any) {
            flash.error(`Error saving claim: ${err.message}`);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({
            [name]: value
        })
    }

    const Trigger = () => cloneElement(trigger, { disabled: open, onClick: () => dispatch({ open: true })});

    return (
        <>
            <Trigger/>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Add Claim
                </DialogTitle>
                <DialogContent dividers>
                    {!ready && <Loading/>}
                    {ready && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={comment?.get('from.name')}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    value={comment?.get('message')}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Lot"
                                    value={claim?.lot}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Quantity"
                                    value={claim?.qty}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Price"
                                    value={claim?.price}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Reply"
                                    fullWidth
                                    name="reply"
                                    placeholder="Enter a reply to be posted to the customer"
                                    value={reply}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel  
                                    label="Add 'Like' to customer comment"
                                    control={
                                        <Checkbox 
                                           name="like"
                                           checked={like}
                                           onChange={({ target: { checked }}:ChangeEvent<HTMLInputElement>) => dispatch({ like: checked })}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                {ready && (
                    <DialogActions>
                        <CancelButton onClick={handleClose}/>
                        <CheckButton onClick={() => save()}/>
                    </DialogActions>
                )}
            </Dialog>
        </>
    )
}
