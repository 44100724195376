import { useCallback, useState } from 'react';

export const useDashboard = () => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
  
    const toggleMenu = useCallback(() => {
        console.log('toggle menu', menuOpen)
        setMenuOpen(prev => !prev);
    },[menuOpen]);

    return { menuOpen, toggleMenu }
    
}