import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Picker from 'emoji-picker-react';
import { useSessionContext, useFirebaseContext, useRefs, useEventContext } from 'hooks';
import { DocumentReference, DocumentSnapshot, getDoc, setDoc, doc } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GenericObject } from 'components';
import { DateTime } from 'luxon';
import { UserRoles } from '@kwixl/interface';
import { debug } from 'lib';

const elementId = '__chat-message';

export const ChatInput = ({
    reply,
    onCancel = () => {},
    stream = '',
    pageId = '',
  }:{
    reply?: DocumentSnapshot;
    onCancel?: () => void;
    stream?: string;
    pageId?: string;
  }) => {

    const {mobile, userProfile} = useSessionContext();

    const { event } = useEventContext();

    const {
        firebaseUser,
    } = useFirebaseContext();

    const { 
        organization,
    } = useSessionContext();

    const {
        organizationFacebookPageRef,
        eventChatsRef,
        eventChatRef,
    } = useRefs()

    const [emojiPickerOpen, toggleEmojiPickerOpen] = useState(false);
    const [pageToken, setPageToken] = useState<string>();
    const [videoId, setVideoId] = useState<string>();

    useEffect(() => {
        return () => {
            setPageToken('');
        }
    },[]);

    useEffect(() => {
        (async () => {
            if (pageToken || !pageId || !stream || !organizationFacebookPageRef || !organization || !firebaseUser) return;
            if (stream.match(/videos/gi) && pageId && !pageToken) {
                const page = await getDoc(organizationFacebookPageRef(organization.id, pageId));
                if (page?.exists()) {
                    setPageToken(page.get('page_token'));
                    setVideoId(stream.split('/').pop());
                }
            }
        })();
    },[stream, pageId, organizationFacebookPageRef, organization, firebaseUser, pageToken]);

    const setMessage = (msg: string) => {
        const el = document.getElementById(elementId) as HTMLInputElement;
        if (!el) return;
        el.value = msg;
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          sendMessage();
        }
    };
    
    const clearChat = () => {
        setMessage('');
        toggleEmojiPickerOpen(false);
        if (mobile) {
          window.scrollTo(0, 0);
          document.getElementById(elementId)?.blur();
        }
        onCancel();
    };
    
    const sendMessage = async (msg = '') => {

        if (!msg) {
            const el = document.getElementById(elementId) as HTMLInputElement;
            if (!el) return;
            msg = el.value;
        }

        clearChat();

        if (!msg || !msg.replace(/\s/gi, '').length || !event?.id || !eventChatsRef || !eventChatRef) return;

        const admin = userProfile?.role === UserRoles.admin &&
                        organization?.id === event?.get('orgId');
        try {
            const ref: DocumentReference = doc(eventChatsRef(event.id));
            const payload: GenericObject = {
                uid: firebaseUser?.uid,
                createdAt: DateTime.now().toUTC().toJSDate(),
                type: admin 
                    ? UserRoles.admin
                    : UserRoles.user,
                handle: userProfile?.handle || null,
                displayName: `${userProfile?.firstName} ${userProfile?.lastName}`,
                photoURL: firebaseUser?.photoURL || null,
                eventId: event?.id,
                orgId: event?.get('orgId'),
                message: msg,
                deletedAt: null,
                parent: reply ? reply.get('comment') || null : null,
            }
            debug(`Adding chat with payload`, payload);
            await setDoc(ref, payload, { merge: true });
            debug('Added chat', ref.id, 'for', event?.id);

        } catch (err: any) {
            console.error(err.message);
        }


    };

    const addEmoji = (emoji: any) => {
        const el = document.getElementById(elementId) as HTMLInputElement;
        if (!el) return;
        el.value += ` ${emoji.emoji}`;
    };

    return (
        <Paper
            component="form"
                sx={{ 
                    p: '2px 4px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: '100%', 
                    color: '#fffff0' ,
                    margin: 0,
                    background: 'transparent',
                    border: '1px solid #fffff0',
                    borderRadius: '20px',
                }}
            >
                {reply && (
                    <Box
                        width='87%'
                        position="absolute"
                        bottom={55}
                        left={15}
                        height={65}
                        sx={{ 
                            background: 'rgba(255, 255, 255, .8)', 
                            color: '#121212', 
                            borderRadius: '10px 10px 0 0',
                        }}
                    >
                        <Box padding={'5px 5px 0'} fontSize="small" display="flex" flexDirection="row" justifyContent='space-between'>
                            <Box>
                                Replying to <Typography fontSize="small" display="inline" fontWeight="bold">{reply.get('displayName')}</Typography>
                            </Box>
                            <Box fontWeight='bold' sx={{ cursor: 'pointer' }} onClick={clearChat}>
                                Cancel
                            </Box>
                        </Box>
                        <Box padding={'5px'}>
                            <Typography fontSize="small" noWrap>
                                {reply.get('message')}
                            </Typography>
                        </Box>
                    </Box>
                )}
            <InputBase
                id={elementId}
                sx={{ ml: 1, flex: 1, color: '#fffff0' }}
                placeholder="Enter your message"
                inputProps={{ 'aria-label': 'enter your message' }}
                onKeyDown={handleKeyPress}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="send" onClick={() => sendMessage()}>
                <SendIcon htmlColor="#fffff0" />
            </IconButton>
            <Divider 
                sx={{ 
                    height: 28, m: 0.5, 
                    backgroundColor: '#c2c2c2',
                    display: { xs: 'none', md: 'initial' }
                }} 
                orientation="vertical" 
            />
            {!mobile && (
                <span
                    style={{
                        cursor: 'pointer',
                        fontSize: '1.5em',
                        margin: '0 10px',
                    }}
                    onClick={() => toggleEmojiPickerOpen(!emojiPickerOpen)}
                >
                    {'🙂'}
                </span>
            )}
            {!mobile && (
                <div
                    style={{
                        display: emojiPickerOpen ? 'block' : 'none',
                        position: 'absolute',
                        bottom: '73px',
                        right: '25px',
                    }}
                >
                    <Picker onEmojiClick={addEmoji} />
                </div>
            )}
        </Paper>
    );
}