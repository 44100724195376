import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import { plans as availablePlans } from 'const';

export const Pricing = ({ 
    plans = ['free', 'pro', 'business'],
    upgrade = false,
    current = 'free',
    onClick = () => {},
}:{ 
    plans?: string[];
    upgrade?: boolean;
    onClick?: (id: string) => void;
    current?: string;
 }) => {

    return (
        <Box 
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap="nowrap"
        >
            {plans.map((id: string) => {
                const plan = availablePlans.find(p => p.id === id);
                return plan && (
                    <Box 
                        flex={1} 
                        padding={5} 
                        margin={{ xs: '10px', md: '30px' }}
                        sx={{ 
                            backgroundColor: plan.color, 
                            color: 'white',
                            borderRadius: '10px',
                        }}
                        display='flex'
                        flexDirection='column'
                    >
                        <Typography variant="h4" textAlign="center" fontWeight="bold">
                            {plan.name}
                        </Typography>
                        <Box flexGrow={1}>
                            <List
                                sx={{ 
                                    listStyleType: 'disc',
                                    pl: 4,
                                }}
                            >
                                <ListItem sx={{ display: 'list-item' }}>
                                    {`${plan.contacts/1000}K contacts`}<sup>*</sup>
                                </ListItem>
                                {plan.items.map((s: string) => (
                                    <ListItem sx={{ display: 'list-item' }}>
                                        <ListItemText primary={s}/>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box mt={3}>
                            <Typography fontSize='smaller' textAlign='center'>
                                {plan.priceDescription}
                            </Typography>
                            <Typography fontWeight={'bold'} fontSize='2em' textAlign='center'>
                                ${plan.price}/mo
                            </Typography>
                            <Typography fontSize='smaller' textAlign='center'>
                                {plan.extra}
                            </Typography>
                        </Box>
                        {(upgrade && plan.price > 0) && (
                            <Box mt={1}>
                                <Box textAlign='center' mt={1}>
                                    {(current !== plan.id) && (
                                        <Button color="secondary" variant='contained' onClick={() => onClick(plan.id)}>
                                            Activate {plan.name}
                                        </Button>
                                    )}
                                    {(current === plan.id) && (
                                        <Box 
                                            sx={{ 
                                                backgroundColor: 'success',
                                            }}
                                            mt={2}
                                            margin='0 auto'
                                            width='80%'
                                            color='#ffffff'
                                            textAlign='center'
                                            fontWeight='bold'
                                            fontSize='1.3em'
                                            border='1px solid #ffffff'
                                            padding={2}
                                        >
                                            Current Plan
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    {((current !== plan.id) && (DateTime.now().day > 1)) && (
                                        <Box textAlign='center' mt={1} fontSize='small'>
                                            ${(((DateTime.now().daysInMonth - (DateTime.now().day-1))/DateTime.now().daysInMonth) * plan.price).toFixed(2)} due today
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}

                    </Box>
                )}
            )}
        </Box>
    )
}

export default Pricing;

