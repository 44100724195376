import { ReactNode } from 'react';
import {Trademark} from './Trademark';
import Typography from '@mui/material/Typography';

export interface BaseNameProps {
    basic?: boolean;
    children?: ReactNode;
    bold?: boolean;
    fontWeight?: string;
}
export interface NameProps extends BaseNameProps {
    text: string;
}

export const Name = (props: NameProps) => {
  const { text, children, bold, basic, fontWeight = 'inherit', ...rest } = props;
  return (
    <Typography display="inline-block" fontSize="inherit" fontWeight={ bold ? 'bold' : fontWeight } {...rest}>
        { basic 
          ? text
          : <Trademark>
              {text}
            </Trademark>
        }
      {children}
    </Typography>
  )
}

export const AppName = (props: BaseNameProps) => <Name text={process.env.REACT_APP_NAME || 'Kwixl'} {...props}/>

export const KwixlChatText = (props: BaseNameProps) => <Name text={'KwixlChat'} {...props}/>

export const KwixlCamText = (props: BaseNameProps) => <Name text={'KwixlCam'} {...props}/>

export const YoutubeText = (props: BaseNameProps) => <Name text={'Youtube'} {...props}/>

export const FacebookText = (props: BaseNameProps) => <Name text={'Facebook'} {...props}/>

export const InstagramText = (props: BaseNameProps) => <Name text={'Instagram'} {...props}/>

export const TiktokText = (props: BaseNameProps) => <Name text={'Tiktok'} {...props}/>

export const StripeText = (props: BaseNameProps) => <Name text={'Stripe'} {...props}/>

export const PayPalText = (props: BaseNameProps) => <Name text={'Paypal'} {...props}/>

export const GoogleText = (props: BaseNameProps) => <Name text={'Google'} {...props}/>