import { ReactNode } from 'react';

export const Trademark = ({children, bold = false}:{ children: ReactNode, bold?: boolean }) => (
  <span style={{fontWeight: bold ? 'bold' : 'inherit'}}>
    {children}
    <sup style={{ fontSize: '.7em', fontWeight: 'normal' }}>
      &trade;
    </sup>
  </span>
);
