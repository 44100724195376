import { ChangeEvent, useEffect, useReducer, type JSX } from 'react';
import styled from '@emotion/styled';
import {reducer, flash, titleCase, FBApi} from 'lib';
import {PricingContent, VideoProvidersContent} from 'content';
import {ContentModal} from 'modals';
import {useFirebaseContext, useFacebookContext, useRefs} from 'hooks';
import {VideoProvider, VideoStatus, EventStream, serialize} from '@kwixl/interface';
import {setDoc} from 'firebase/firestore';
import {
  OptimizedVideo, 
  BroadcastDesktop, 
  CdnImage,
  Trademark,
  FacebookText,
  YoutubeText,
  AppName,
  Loading,
  PrimaryButton,
  CancelButton,
  CheckButton,
  GenericObject,
  Money,
} from 'components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Facebook from '@mui/icons-material/Facebook';
import YouTube from '@mui/icons-material/YouTube';
import Box from '@mui/material/Box';
import VideocamIcon from '@mui/icons-material/Videocam';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface Provider {
  icon?: JSX.Element;
  image?: string;
  text: () => JSX.Element;
  hourly: number;
  viewerLimit: string;
}

const ProviderList: {[key:string]: Provider} = {
  [VideoProvider.facebook]: {
    icon: <Facebook color="primary" fontSize="large"/>,
    text: () => (
      <div>
        <p key="1">
          Public video stream with highest lag time (typically 20-30 seconds
          from <FacebookText />
          ).
        </p>
        <p key="2">
          Broadcast to your timeline or a page you manage (groups not currently
          supported.)
        </p>
      </div>
    ),
    hourly: 4.99,
    viewerLimit: 'Unlimited Viewers',
  },
  [VideoProvider.youtube]: {
    icon: <YouTube color="error" fontSize="large"/>,
    text: () => (
      <div>
        <p>
          Public video stream with high compatibility across devices and
          browsers, minimal lag time from <YoutubeText /> (3-4 secs).
        </p>
        <p>
          Requires an eligible <YoutubeText /> channel.
        </p>
      </div>
    ),
    hourly: 4.99,
    viewerLimit: 'Unlimited Viewers',
  },
  [VideoProvider.kwixl]: {
    image: '/icons/apple-icon-114x114.png',
    text: () => (
      <div>
        <p>
          Private video stream with highest compatibility across devices and
          browsers and lowest lag time (&lt;1 second).
        </p>
        <p>(Additonal video charges may apply.  Please see our pricing guide for details.)</p>
      </div>
    ),
    hourly: 4.99,
    viewerLimit: 'Up to 1 Million Viewers',
  },
};

const initialState = {
  stage: 'provider',
  url: null,
  provider: VideoProvider.kwixl,
  streamKey: null,
  token: null,
  preview: false,
  loading: false,
  status: {},
  fbVideo: {modal: false},
  modalOpen: false,
};

/*
interface Props {
  eventId?: string;
  trigger?: any;
  open?: boolean;
  providers?: string[];
  onOpen?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  onSuccess: () => void;
}
*/

export const extractIdFromFacebookVideoUrl = (url: string) => {
  const parts = url.replace(/\/+$/, '').replace(/\/\//g, '').split('/');
  const targetId = parts[1];
  const streamId = parts[3];
  return {targetId, streamId};
};

export const VideoSource = ({
  label = 'Add Video Stream',
  eventId,
  mobile = false,
  onOpen = () => {},
  onClose = () => {},
  onCancel = () => {},
  onSuccess = () => {},
  providers = [
    VideoProvider.kwixl,
    VideoProvider.youtube,
    VideoProvider.facebook,
  ],
}:{
  label?: string;
  eventId: string;
  mobile?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  providers?: VideoProvider[];
}) => {

  const {callable} = useFirebaseContext();
  const {eventStreamRef} = useRefs();
  const {extendedPermissions} = useFacebookContext();

  const [
    {
      stage,
      url,
      provider,
      streamKey,
      token,
      preview,
      loading,
      status,
      fbVideo,
      modalOpen = false,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const reset = () => {
    dispatch(initialState);
  };

  useEffect(() => {
    if (modalOpen) onOpen();
  },[modalOpen]);

  const handleClose = () => {
    reset();
    onCancel();
    onClose();
  };

  const handleEmbedVideo = async () => {
    dispatch({loading: true});
    try {
      await setDoc(eventStreamRef!(eventId || 'x'), {
        provider: VideoProvider.embed,
        status: VideoStatus.none,
        url,
        type: VideoProvider.embed,
      });
      dispatch({stage: 'skip'});
    } catch (err: any) {
      flash.error(
        `There was an error updating your event stream: ${err.message}`
      );
      handleClose();
    } finally {
      dispatch({loading: false});
    }
  }

  const handleKwixlVideo = async () => {
    dispatch({loading: true});
    try {
      await setDoc(eventStreamRef!(eventId || 'x'), {
        provider: VideoProvider.kwixl,
        status: VideoStatus.none,
      });
      dispatch({stage: 'skip'});
    } catch (err: any) {
      flash.error(
        `There was an error updating your event stream: ${err.message}`
      );
      handleClose();
    } finally {
      dispatch({loading: false});
    }
  };

  const handleSuccess = async () => {
    const eventStream = EventStream.load({
      status: VideoStatus.none,
      provider,
      url: '',
      type: 'live',
    });
    if (provider === VideoProvider.facebook) {
      const {targetId, streamId} = extractIdFromFacebookVideoUrl(url);
      eventStream.targetId = targetId;
      eventStream.streamId = streamId;
    }
    // update event_stream
    let videoType = 'live';
    switch (provider) {
      case VideoProvider.facebook:
      case VideoProvider.youtube:
        eventStream.url = url;
        eventStream.streamKey = streamKey;
        eventStream.token = token;
        break;
      case VideoProvider.embed:
        eventStream.url = url;
        eventStream.type = VideoProvider.embed;
        break;
    }
    await setDoc(
      eventStreamRef!(eventId || 'x'),
      {...serialize(eventStream)},
      {merge: true}
    );
    onSuccess();
    /*if (resetVideo)*/ reset();
  };

  const messageHandler = async (e: any) => {
    if (!!e.data && typeof e.data === 'string') {
      try {
        const {code} = JSON.parse(e.data);
        if (code && code !== '') {
          // save share event to responses db for reporting
          dispatch({loading: true});
          const {data} = await callable!('youtube-createStream', {
            eventId,
            code,
          });
          const {success, streamKey, url} = data || {};
          dispatch({loading: false});
          if (success) {
            dispatch({
              streamKey,
              url,
              status: {
                success: true,
                message:
                  'Your livestream was successfully created.  The stream key and url have been added below.',
              },
            });
          } else {
            dispatch({
              status: {
                success: false,
                message:
                  'An error occurred while trying to automatically create your livestream.  Please check that your account on the selected provider allows you to create live streams and embed livestream videos. If the problem persists you will need to configure your livestream manually.',
              },
            });
          }
        }
      } catch (err: any) {
        console.error(err.message);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const cleanFacebookUrl = (value: string) => {
    let cleaned = value;

    if (value.indexOf('<iframe') > -1) {
      // full copy from FB live...uk-icon-exclamation-triangle
      try {
        const parts = value.split(' ');
        const src = parts.find(val => val.indexOf('src') === 0);
        if (src) {
          let s = new URL(src.substring(4).replace(/"/gi, ''));
          let t = decodeURIComponent(s.href);
          const [, b,] = t.split('/videos/');
          if (b) {
            cleaned = b.split('/')[0];
          }
        }
      } catch (err) {}
    }

    return cleaned;
  };

  const extractUrl = (value: string) => {
    let extracted = value;

    if (
      value.indexOf('iframe') > 0 &&
      value.indexOf(VideoProvider.facebook) > 0
    ) {
      extracted = cleanFacebookUrl(value);
    }

    dispatch({
      url: extracted,
    });
  };

  const previewVideo = () => {
    dispatch({preview: true});
  };

  const backButton = (stage: string) => (
    <PrimaryButton startIcon={<ArrowBackIcon/>} onClick={() => dispatch({stage})}>
      Back
    </PrimaryButton>
  );

  const getVideoUrl = () => {
    return (
      <>
        <DialogTitle>Livestream Embed URL</DialogTitle>
        <DialogContent style={{overflow: 'hidden'}}>
          {(preview && url) && (
            <div
              id="video-column"
              style={{
                margin: '0 auto 20px',
                overflow: 'hidden',
                width: '400px',
                textAlign: 'center',
              }}
            >
              <OptimizedVideo url={url} preview={true}/>
            </div>
          )}
          <p>
            Specify the embed URL for your video stream, then click the Preview
            button to confirm it is working.
          </p>
          <p>
            <b>PLEASE NOTE:</b> By using an embedded url, both you and your
            viewers will see the delayed video.
          </p>
              <TextField
                fullWidth
                name="streamUrl"
                value={url}
                placeholder="Copy and paste the video stream URL here. Ex. https://www.facebook.com/0000000/videos/1234567890"
                onChange={({ target: {value}}: ChangeEvent<HTMLInputElement>) => {
                  //togglePreview(false);
                  extractUrl(value);
                }}
              />
        </DialogContent>
        <DialogActions>
          {backButton('provider')}
          <CancelButton close onClick={handleClose}/>
          <PrimaryButton
            disabled={!url || url === ''}
            startIcon={<VisibilityIcon/>}
            onClick={previewVideo}
          >
            Preview
          </PrimaryButton>
          <CheckButton
            disabled={!url || url === ''}
            onClick={handleEmbedVideo}
          >
            Use This Video
          </CheckButton>
        </DialogActions>
      </>
    );
  };

  const ProviderInfo = ({ provider, data }: { provider: VideoProvider | string, data: GenericObject }) => {
    return (
      <Box display="flex" flexDirection="row" fontSize="small">
        <Box flex="0 1 100px">
          {data.image ? (
            <CdnImage centered height={48} width={48} link src={data.image} permanent={true}/>
          ) : (
            <Box>{data.icon}</Box>
          )}
        </Box>
        <Box flexGrow={1} ml={2}>
          <ProviderTitle>
            <Trademark>{titleCase(provider)}</Trademark>
          </ProviderTitle>
          <Stack direction="row" spacing={2}>
            {data.hourly > 0 && <span><Money value={data.hourly}/>/hr</span>}
            {data.viewerLimit && (
              <span>{data.viewerLimit}</span>
            )}
          </Stack>
          {data.text()}
        </Box>
    </Box>
  )}

  const chooseProvider = () => {

    return (
      <>
        <DialogTitle>Choose Stream Provider</DialogTitle>
        <DialogContent>
          <p>
            Choose how to broadcast your live video to your viewers. You can
            broadcast to <YoutubeText /> or <FacebookText /> and we'll embed
            that video feed in the event page for your viewers, or use the
            private <AppName /> video feed for maximum performance and best user
            experience. <ContentModal content={<VideoProvidersContent/>} header="Video Providers" trigger={<a style={{cursor: 'pointer'}}>Learn more</a>}/> about how Video Providers work and see our <ContentModal content={<PricingContent/>} header="Kwixl Pricing Guide" trigger={<a style={{cursor:'pointer'}}>Pricing Guide</a>}/>.
          </p>
          <Divider />
          {providers.map(provider => {
            const p = ProviderList[provider];
            if (!p) return null;
            return (
              <SelectableRow
                onClick={() => {
                  switch (provider) {
                    case VideoProvider.kwixl:
                      handleKwixlVideo();
                      break;
                    default:
                      dispatch({
                        provider,
                        stage: 'key',
                      });
                  }
                }}
              >
                <ProviderInfo provider={provider} data={p}/>
              </SelectableRow>
            );
          })}
          {/*process.env.NODE_ENV !== 'production' && (
            <SelectableRow onClick={() => dispatch({ provider: VideoProvider.embed, stage: 'url'})}>
              <ProviderInfo 
                provider='Embedded Video'
                data={{
                  icon: <>Icon</>,
                  text: () => (
                    <div>
                      <p>
                        Embed a live-stream or pre-recorded video URL.
                      </p>
                    </div>
                  )
                }}
              />
            </SelectableRow>
              )*/}
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}/>
        </DialogActions>
      </>
    );
  };

  const skipped = () => {
    return (
      <>
        <DialogTitle>Setup Event Stream - Complete</DialogTitle>
        <DialogContent>
          <p>
            You're all set...Just start your live video from the event screen
            when you start your event!
          </p>
        </DialogContent>
        <DialogActions>
          <CheckButton
            onClick={() => {
              onSuccess();
              handleClose();
            }}
          >
            Done
          </CheckButton>
        </DialogActions>
      </>
    );
  };

  const createFacebookStream = async ({
    pages, 
    groups, 
    userID,
    accessToken
  }:{
    pages?: GenericObject;
    groups?: GenericObject;
    userID?: string;
    accessToken?: string;
  }) => {

    try {
      if (!pages) pages = fbVideo?.pages || [];
      //if (!groups) groups = fbVideo?.groups || [];
      if (!userID) userID = fbVideo?.userID || '';
      if (!accessToken) accessToken = fbVideo?.token || '';

      // determine where the stream is to be published
      let type = 'user';
      let id = userID;
      let streamToken = accessToken;

      /*
      if (fbVideo.group_index) {
        const group = groups.data[fbVideo.group_index];
        type = 'group';
        id = group.id;
      }
      */

      if (fbVideo.page_index) {
        const page = pages?.data[fbVideo.page_index];
        type = 'page';
        id = page.id;
        streamToken = page.access_token;
      }

      dispatch({
        fbVideo: {
          modal: false, 
          type, 
          id, 
          token: streamToken
        }
      });
      // Only create the stream here if an event id was
      // passed.  Flash sale setup does not yet have the
      // event id and will be created from the info
      // return from this module;
      if (eventId) {
        dispatch({loading: true});
        const {data} = await callable!('facebook-createStream', {
          eventId,
          token: streamToken,
          target: type,
          targetId: id,
        });
        const {success, token, streamKey, url} = data;
        if (!success) {
          throw new Error('Error publishing video');
        }
        dispatch({
          loading: false,
          token,
          streamKey,
          url,
          status: {
            success: true,
            message:
              'Your livestream was successfully created.  The stream key and url have been added below.',
          },
        });
      } else {
        handleSuccess();
      }
    } catch (err: any) {
      console.error(err.message);
      dispatch({
        fbVideo: {modal: false},
        loading: false,
        status: {
          success: false,
          message: err.message,
        },
      });
    }
  };

  const setupBroadcast = async () => {
    dispatch({loading: true, streamKey: '', url: ''});
    switch (provider) {
      case VideoProvider.youtube:
        const {data} = await callable!('youtube-getAuthUrl', {
          redirect: `${window.location.origin}/auth/oauth/yt`,
        });
        window.open(data);
        break;
      case VideoProvider.facebook:
        const response: any = await new Promise(resolve => {
          window.FB.login(
            (response: any) => {
              resolve(response);
            },{
                config_id: process.env.REACT_APP_FACEBOOK_LOGIN_PROFILE,
                scope: extendedPermissions?.join(','),
            });
        });
        if (!response || !response?.authResponse) return;
        const {
          authResponse: {accessToken, userID},
        } = response;

        const pages = await FBApi(
          `/${userID}/accounts?fields=name,access_token`,
          'GET',
          accessToken
        );

        dispatch({
          fbVideo: {
            pages,
            userID,
            token: accessToken,
            modal: true,
          },
        });
        break;
    }
    dispatch({loading: false});
  };

  const getStreamKey = () => {
    return (
      <>
        <DialogTitle>
          Enter Stream Key for <Trademark>{titleCase(provider)}</Trademark>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Loading>
              Please wait while we configure your live stream...
            </Loading>
          ) : (
            <>
              <p>
                <Trademark>{process.env.REACT_APP_NAME}</Trademark> can
                automatically setup your livestream for you, or you can set it
                up yourself and then provide the stream key and url below.
              </p>
              <Box mb={2}>
                <PrimaryButton startIcon={<VideocamIcon/>} loading={loading} disabled={loading} onClick={setupBroadcast}>
                  Set it up for me
                </PrimaryButton>
              </Box>
              { status.message && (<Alert severity={status.success ? 'success' : 'error'}>{status.message}</Alert>)}
              <Divider />
              {/*<p>Once you have setup your live broadcast on <Trademark>{ titleCase(streamProvider) }</Trademark>, please provide the stream key and shared URL below.</p>*/}
              <Box mt={1} mb={2}>
                  <TextField
                    label="Stream Key"
                    fullWidth
                    name="streamKey"
                    value={streamKey || ''}
                    placeholder="Please enter the stream key from your chosen provider"
                    onChange={({target: {value}}: ChangeEvent<HTMLInputElement>) => {
                      dispatch({streamKey: value});
                    }}
                  />
                </Box>
                <Box mt={1} mb={2}>
                  <TextField
                    fullWidth
                    label="Stream URL"
                    name="streamUrl"
                    value={url || ''}
                    placeholder="Please enter the stream URL from your provider"
                    onChange={({ target: {value} }) => {
                      if (
                        provider === VideoProvider.facebook &&
                        value.indexOf('<iframe') > -1
                      ) {
                        // strip out embed code
                        value = value.substr(value.indexOf('href=') + 5);
                        value = decodeURIComponent(
                          value.substr(0, value.indexOf('" width'))
                        );
                        if (value.indexOf('&width') > -1) {
                          value = value.substr(0, value.indexOf('&width'));
                        }
                      }
                      dispatch({url: value});
                    }}
                  />
                </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {backButton('provider')}
          <CancelButton onClick={handleClose}/>
          <CheckButton onClick={handleSuccess}>
            Finish
          </CheckButton>
        </DialogActions>
      </>
    );
  };

  const previewRemoteStream = () => {
    return (
      <>
        <DialogTitle>Preview Video Stream</DialogTitle>
        <DialogContent>
          <Grid container columns={2} spacing={2}>
              <Grid item id="preview-wrapper" style={{overflow: 'hidden'}}>
                <BroadcastDesktop key={'__video_preview'} preview={true} />
              </Grid>
              <Grid item>
                <OptimizedVideo url={url} />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CheckButton onClick={handleSuccess}>
            Use This Video
          </CheckButton>
        </DialogActions>
      </>
    );
  };

  const Trigger = () => mobile 
    ? (
      <IconButton onClick={() => dispatch({ modalOpen: true })}>
        <VideocamIcon fontSize="large" color="info"/>
      </IconButton>
    ) : (
      <Button color="primary" disabled={modalOpen} startIcon={<VideocamIcon/>} onClick={() => dispatch({ modalOpen: true })}>
        {label}
      </Button>
    )

  return (
    <>
    <Trigger/>
    <Dialog
      open={modalOpen}
      onClose={() => handleClose()}
      disableEscapeKeyDown={true}
    >
      <>
      {(() => {
        switch (stage) {
          case 'url':
            return getVideoUrl();
          case 'provider':
            return chooseProvider();
          case 'key':
            return getStreamKey();
          case 'remotePreview':
            return previewRemoteStream();
          case 'broadcast':
            return setupBroadcast();
          case 'kwixl':
            return 'Setting up broadcast';
          case 'skip':
            return skipped();
          default:
            return chooseProvider();
        }
      })()}
      {fbVideo.modal && (
        <Dialog
          open={fbVideo.modal}
          maxWidth="sm"
          disableEscapeKeyDown={true}
        >
          <DialogTitle>Select Livestream Destination</DialogTitle>
          <DialogContent>
            <p>
              Please select where you would like to publish your livestream on{' '}
              <FacebookText />.
            </p>
              {fbVideo.pages &&
                fbVideo.pages.data &&
                fbVideo.pages.data.length > 0 && (
                    <Select
                      fullWidth
                      label="Pages You Manage"
                      value={fbVideo.page_index}
                      onChange={({target: {value}}: SelectChangeEvent<HTMLInputElement>) =>
                        dispatch({
                          fbVideo: {
                            ...fbVideo,
                            me: false,
                            group_index: null,
                            page_index: value,
                          },
                        })
                      }
                    >
                      {fbVideo.pages.data.map(({name}:{name:string}, index: number) => <MenuItem value={index}>{name}</MenuItem>)}
                    </Select>
                )}
              {/*fbVideo.groups &&
                fbVideo.groups.data &&
                fbVideo.groups.data.length > 0 && (
                  <Form.Group>
                    <Form.Select
                      width={16}
                      label="Groups You Belong To"
                      value={fbVideo.group_index}
                      options={fbVideo.groups.data.map(
                        ({ id, name }, index) => ({
                          key: index,
                          value: index,
                          text: name,
                        })
                      )}
                      onChange={(e, { value }) =>
                        dispatch({
                          fbVideo: {
                            ...fbVideo,
                            me: false,
                            page_index: null,
                            group_index: value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                )*/}
              {/*<Form.Group>
                <Form.Checkbox
                  label={"Publish to my timeline"}
                  checked={fbVideo.me}
                  onChange={(e, { checked }) =>
                    dispatch({
                      fbVideo: {
                        ...fbVideo,
                        me: checked,
                        page_index: null,
                        group_index: null,
                      },
                    })
                  }
                />
              </Form.Group>
                */}
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={() => dispatch({fbVideo: {...fbVideo, modal: false}})}/>
            <CheckButton
              disabled={
                fbVideo.page_index === null &&
                fbVideo.group_index === null &&
                fbVideo.me === false
              }
              onClick={() => createFacebookStream!({})}
            >
              Continue
            </CheckButton>
          </DialogActions>
        </Dialog>
      )}
      </>
    </Dialog>
    </>
  );
};

const SelectableRow = styled.a`
  display: block;
  cursor: pointer;
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 10px;
  color: initial;
  border-radius: 10px;
  webkit-border-radius: 10px;
  &:hover {
    background-color: #e2e2e2;
  }
`;

const ProviderTitle = styled.span`
  font-size: 1.5em;
  font-weight: bolder;
  display: block;
  margin-bottom: 0.5em;
`;
