import { useReducer, useEffect, ChangeEvent, type JSX } from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {CARD_OPTIONS} from 'const';
import {BillingTerms} from 'components';
import {useFirebaseContext, useSessionContext} from 'hooks';
import {flash, reducer} from 'lib';
import {Loading, AppName, PrimaryButton} from 'components';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Check';
import { Modal } from 'modals';
import Box from '@mui/material/Box';

const initialState = {
  active: false,
  loading: false,
  intent: null,
  agree: false,
};

interface Props {
  open?: boolean;
  trigger?: JSX.Element;
  onSuccess?: (intent: any) => void;
  onClose?: () => void;
  business?: boolean;
  label?: string;
  fluid?: boolean;
  vendor?: string;
}

export const AddCardModal = ({
  open = false,
  trigger = <PrimaryButton startIcon={<CreditCardIcon/>} onClick={() => dispatch({ open: true })}>
              {'Add Payment Method'}
            </PrimaryButton>,
  onSuccess = () => {},
  onClose = () => {},
  business = false,
  label,
  fluid = false,
  vendor,
}: Props) => {
  const {callable} = useFirebaseContext();

  const {userProfile} = useSessionContext();
  
  const [{active, loading, intent, agree}, dispatch] = useReducer(
    reducer,
    initialState
  );

  const stripe = useStripe();
  const elements = useElements();

  const setup = async () => {
    const {data, error} = await callable!('billing-setup');
    if (!data || error) {
      console.log(error);
      flash.error('There was an error setting up for card payments.');
      close();
    }
    dispatch({intent: data});
  };

  useEffect(() => {
    dispatch({ active: open });
  },[open]);

  useEffect(() => {
    (async () => {
      if (!active) return;
      if (!intent) await setup();
    })()
  },[active]);

  const addCard = async () => {
    if (!intent || !stripe || !elements) {
      flash.error('Error setting up card');
      return;
    }

    dispatch({loading: true});

    /* TODO: collect other billing details (name, email, phone) */
    const card = elements.getElement(CardElement);
    if (!card) {
      flash.error('Could not get card info');
      return;
    }

    const {error, setupIntent} = await stripe.confirmCardSetup(intent.key, {
      payment_method: {
        card,
        billing_details: {
          name: `${userProfile?.firstName} ${userProfile?.lastName}`,
        },
      },
    });

    if (error) {
      // Show error to your customer (e.g., insufficient funds)
      flash.error(error.message || '');
    } else {
      // The payment has been processed!
      switch (setupIntent.status) {
        case 'succeeded':
          const {payment_method} = setupIntent;
          await callable!('billing-update', {payment_method});
          flash.success('Your card was successfully added!');
          onSuccess(setupIntent);
          close();
          break;
        default:
          flash.error('Unknown response received from processor.');
          break;
      }
    }

    dispatch({loading: false});
  };

  const close = () => {
    dispatch(initialState);
    onClose();
  };

  const handleAgree = (e: ChangeEvent<HTMLInputElement>) => dispatch({agree: e.target.checked});

  return (
    <Modal
      trigger={trigger}
      open={active}
      opened={() => dispatch({ active: true })}
      closed={() => close()}
      title={`Add/Update ${business ? 'Billing Method' : 'Card'}`}
      fullWidth
      maxWidth='sm'
    >
      {intent ? (
        <>
          <DialogContent>
            <CardElement options={CARD_OPTIONS} />
            <Divider />
            <Box mt={2} display="flex" flexDirection="row">
              <Box ml={1} flexGrow={1} fontSize="small">
                I authorize 421, LLC dba <AppName/> to send
                instructions to the financial institution that issued my card to
                take payments from my card account {vendor ? <>on behalf of <strong>{vendor}</strong></> : ''} in accordance with the{' '}
                <BillingTerms />.
              </Box>
              <Box flex={'0 1 100px'} display="flex" flexDirection="column" justifyContent="flex-start" textAlign='right'>
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  name="agree"
                  checked={agree}
                  onChange={handleAgree}
                  style={{
                    marginLeft: 0,
                    marginBottom: 'inherit',
                    marginRight: '10px',
                    fontSize: '.75em',
                    verticalAlign: 'middle',
                    display: 'inline'
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              variant="contained"
              startIcon={<CheckIcon/>}
              onClick={addCard}
              loading={loading}
              disabled={!agree || loading || !intent || !stripe || !elements}
            >
              Save Card Info
            </PrimaryButton>
          </DialogActions>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
