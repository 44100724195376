
import MailChimpSubscribe from 'react-mailchimp-subscribe';
import { EmailForm } from './EmailForm';

export const Subscribe = () => {

    return (
        <MailChimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_SUBSCRIBE || ''}
            render={({ subscribe, status, message }) => (
                <EmailForm
                    status={status} 
                    message={message}
                    onValidated={(formData: any) => subscribe(formData)}
                />
            )}
        />
    )

}