import React, {useReducer, useEffect, useRef, PropsWithChildren} from 'react';
import {reducer} from 'lib';
import throttle from 'lodash/throttle';
import {checkMobile, checkTablet, GenericObject} from 'components';
import {loadStripe} from '@stripe/stripe-js';
import {DocumentSnapshot, getDoc} from 'firebase/firestore';
import {useFirebaseContext, useRefs} from 'hooks';
import { useCollectionOnce, useDocument } from 'react-firebase-hooks/firestore';
import type { Channel, Merchant, Modules } from 'types';

export interface ISessionContext {
  live: boolean;
  ready: boolean;
  orientation: string;
  mobile: boolean;
  tablet: boolean;
  demo: boolean;
  locale: string;
  setup_payment_method: boolean;
  stripePromise: any;
  demoDoc: DocumentSnapshot;
  channel: Channel;
  deviceId: string;
  userCanSell: boolean;
  userProfile: GenericObject;
  organization: GenericObject;
  partners: string[];
  merchant: Merchant,
  userHome: string;
  updateSession: (data: {[key: string]: any}) => void;
  setupChannel: (id: string, store: string) => Promise<void>;
  checkMerchantAccountAndBilling: () => Promise<GenericObject>;
  userFullName: () => string;
  appModules: Modules;
  appIntegrations: string[];
}

const defaultState = {
  live: process.env.REACT_APP_STAGE === 'production' ? true : false,
  ready: true,
  orientation: 'landscape',
  mobile: false,
  tablet: false,
  demo: false,
  locale: 'en',
  setup_payment_method: true,
  stripePromise: null,
  channel: null,
  userCanSell: false,
  partners: [],
  userProfile: null,
  organization: null,
  merchant: {
    status: 'none',
    card: null,
    processor: null,
  },
  userHome: '/cp',
  appModules: {},
  appIntegrations: [],
};

export const SessionContext =
  React.createContext<Partial<ISessionContext>>({});

export const SessionProvider = ({children}: PropsWithChildren) => {

  const [state, setState] = useReducer(reducer, defaultState);

  const mobileRef = useRef<boolean>(false);
  const tabletRef = useRef<boolean>(false);

  const {
    shopifyStoreRef,
    organizationRef,
    organizationPrivateDataRef,
    userProfileRef,
    merchantAccountRef,
    organizationModulesRef,
    organizationIntegrationsRef,
  } = useRefs();

  const {
    firebaseUser,
    defaultListenerOptions,
  } = useFirebaseContext();

  const [orgDoc] = useDocument(
    organizationRef!(state.userProfile?.orgId || 'x'),
    defaultListenerOptions,
  )

  const [orgPrivateDoc] = useDocument(
    organizationPrivateDataRef!(state.userProfile?.orgId || 'x'),
    defaultListenerOptions,
  )

  const [orgModulesDoc] = useDocument(
    organizationModulesRef!(state.userProfile?.orgId || 'x'),
    defaultListenerOptions,
  )
  
  const [orgIntegrationsDocs] = useCollectionOnce(
    organizationIntegrationsRef!(state?.userProfile?.orgId || 'x'),
    defaultListenerOptions,
  );

  const [userProfileDoc] = useDocument(
    userProfileRef!(firebaseUser?.uid || 'x'),
    defaultListenerOptions,
  )

  useEffect(() => {
    window.addEventListener('resize', resize);
    window.addEventListener('orientationchange', orientationChange);
    setState({
      stripePromise: loadStripe(process.env.REACT_APP_STRIPE_KEY || ''),
      mobile: checkMobile(),
      orientation:
        window.innerWidth < window.innerHeight ? 'portrait' : 'landscape',
    });
    return () => {
      window.removeEventListener('orientationchange', orientationChange);
      window.removeEventListener('resize', resize);
    };
  }, []);
  
  useEffect(() => {
    if (!orgPrivateDoc?.exists()) return;
    setState({
      userCanSell: orgPrivateDoc.get('permissions.sell'),
      partners: orgPrivateDoc.get('partners') || [],
      loginComplete: true,
    });
  },[orgPrivateDoc]);

  useEffect(() => {
    if (!orgModulesDoc?.exists()) return;
    setState({ 
      appModules: orgModulesDoc.data(),
    })
  },[orgModulesDoc]);

  useEffect(() => {
    if (!orgIntegrationsDocs?.docs?.length) return;
    setState({
      appIntegrations: orgIntegrationsDocs.docs.map(doc => doc.id),
    })
  },[orgIntegrationsDocs]);

  useEffect(() => {
    (async () => {
      if (!orgDoc?.exists()) return;
      await checkMerchantAccountAndBilling().catch((err: any) => console.log('Error getting merchant account', err.message));
      setState({organization: {...orgDoc.data(), id: orgDoc.id, loginComplete: true }});
    })()
  }, [orgDoc]);

  useEffect(() => {
    if (userProfileDoc?.exists()) {
      setState({
        userProfile: {
          ...userProfileDoc?.data() || {}, 
          id: userProfileDoc?.id || 'x',
        },
        loginComplete: true,
      });
    }
  }, [userProfileDoc]);

  useEffect(() => {
    if (state?.demoDoc && !state?.demo) {
      setState({ demo: true });
    }
  },[state]);

  useEffect(() => {
    (async () => {
      if (!state.organization || !state.partners?.length) return;
      const c = window.sessionStorage.getItem('channel_id');
      if (!c) return;
      const s = window.sessionStorage.getItem('channel_store')
      if (!s) return;
      if (state?.channel?.type === c && state?.channel?.id === s) return;
      await setupChannel(c, s);
    })()
  },[state.organization, state.partners]);

  const userFullName = () => {
    let name = `${state.userProfile?.firstName} ${state.userProfile?.lastName}`;
    if (!name.replace(/\s/g, '').trim()) name = firebaseUser?.displayName || '';
    return name;
  };

  const checkMerchantAccountAndBilling = async (): Promise<GenericObject> => {
    if (!orgDoc?.exists()) return {};
    const merchantDoc = await getDoc(
      merchantAccountRef!(orgDoc.id)
    );
    const {status = '', card = '', processor = ''} = merchantDoc.data() as GenericObject || {};
    setState({
      merchant: {
        status: processor === 'paypal' ? 'active' : status,
        card,
        processor,
      }
    });
    return {
      status: processor === 'paypal' ? 'active' : status,
      card,
      processor,
    };
  };

  const resize = throttle(e => {
    let check = checkMobile();
    if (check !== mobileRef.current) {
      mobileRef.current = check;
      setState({mobile: check});
    }
    check = checkTablet();
    if (check !== tabletRef.current) {
      tabletRef.current = check;
      setState({tablet: check});
    }
  }, 250);

  const updateSession = (data: any) => {
    setState(data);
  };

  const setupChannel = async (id: string, store: string) => {
    if (id && store && state.partners?.includes(id) && state.organization) {
      if (id === 'shopify') {
            try {
              const shop = await getDoc(shopifyStoreRef!(store || 'x'));
              if (shop.exists()) {
                  if (shop.get('orgId') === state.organization.id && shop.get('status') === 'authorized') {
                      updateSession({ 
                          channel:{
                              type: id,
                              name: shop.get('name'),
                              id: store,
                          }
                      });
                      window.sessionStorage.setItem("channel_id", id);
                      window.sessionStorage.setItem("channel_store", store);
                  }
              }
            } catch (err: any) {
                console.error(`Invalid channel request`);
            }
        }
    }
  }

  const orientationChange = throttle(e => {
    try {
      const {
        target: {
          screen: {
            orientation: {angle},
          },
        },
      } = e;
      switch (angle) {
        case 90:
        case 270:
          setState({orientation: 'landscape'});
          break;
        default:
          setState({orientation: 'portrait'});
          break;
      }
    } catch (err: any) {
      console.error(err);
    }
  }, 250);

  return (
    <SessionContext.Provider value={{
      ...state, 
      updateSession, 
      setupChannel,
      checkMerchantAccountAndBilling,
      userFullName,
    }}>
      {children}
    </SessionContext.Provider>
  );
};
