import { useReducer, useEffect, useState, cloneElement, type JSX } from 'react';
import {reducer} from 'lib';
import {loadStripe} from '@stripe/stripe-js';
//import {flash} from 'lib';
import {StripeModal} from './StripeModal';
//import {useFirebaseContext} from 'hooks';
import {Elements} from '@stripe/react-stripe-js';
import {Loading, GenericObject, IntlText} from 'components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Button from '@mui/material/Button';

const initialState = {
  ready: false,
  stripeConnectPromise: null,
  processor: null,
};

interface Props {
  onSuccess: (data: GenericObject) => void;
  onClose?: () => void;
  settings: GenericObject;
  trigger?: JSX.Element;
}

export const PaymentModal = ({
  onSuccess,
  onClose = () => {},
  settings,
  trigger = <Button color="success" variant="contained" startIcon={<AttachMoneyIcon/>}>
              <IntlText id="button_pay_now"/>
            </Button>
}: Props) => {

  const [open, setOpen] = useState<boolean>(false);

  //const {callable} = useFirebaseContext();

  const [{stripeConnectPromise}, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      let promise;
      //if (settings?.type === 'billing') {
        promise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
      //} else {
        // init stripe with connected account
        /*
        const {data, errors} = await callable!('merchant-info', {
          id: settings?.orgId,
        });
        if (errors || !data?.merchantId) {
          flash.error('This vendor has not yet setup their payment account.');
          onClose();
          return;
        }
        */
       // promise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')//, {
          //stripeAccount: data.merchantId,
        //});
      //}
      dispatch({stripeConnectPromise: promise});
    })();
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
  }

  const handleSuccess = (data: GenericObject) => {
    onSuccess(data);
    handleClose();
  }

  if (!stripeConnectPromise) return <Loading/>

  const Trigger = () => cloneElement(trigger, { disabled: open, onClick:() => setOpen(true) });
    
  return (
    <>
      <Trigger/>
      <Elements stripe={stripeConnectPromise}>
        <StripeModal
          open={open}
          settings={settings}
          onClose={handleClose}
          onSuccess={handleSuccess}
        />
      </Elements>
     </> 
  );
};
