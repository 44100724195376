import {ROUTES} from 'routes';
import {IntlText,PrimaryButtonLink} from 'components';
import {useSessionContext} from 'hooks';
import Alert from '@mui/material/Alert';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export const BillingMessage = () => {
  
  const {partners = [], userCanSell} = useSessionContext();

  if (!userCanSell) return null;

  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      <p>
        <IntlText id="no_billing_method"/>
      </p>
      {partners.includes('shopify') && (
        <p>
          <IntlText id="add_billing_shopify"/>
        </p>
      )}
      <p>
        <PrimaryButtonLink to={ROUTES.cp_billing}>
          <CreditCardIcon/> 
          <IntlText id="add_billing"/>
        </PrimaryButtonLink>
      </p>
    </Alert>
  )
}
