import React, {useEffect, useReducer, useRef, ReactNode} from 'react';
import { createBrowserHistory } from 'history';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IRemoteVideoTrack,
  IRemoteAudioTrack,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ClientRole,
  CameraVideoTrackInitConfig,
  LiveStreamingTranscodingConfig,
  UID,
} from 'agora-rtc-sdk-ng';
import {checkMobile, GenericObject} from 'components';
import {useEventContext, useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {reducer, flash, debug} from 'lib';
import { nanoid } from 'nanoid';
import {
  updateDoc,
  serverTimestamp,
  setDoc,
  getDoc,
  DocumentSnapshot,
} from 'firebase/firestore';
import {
  useDocument,
} from 'react-firebase-hooks/firestore';
import {VideoStatus,VideoProvider as VideoProviderTypes } from '@kwixl/interface';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const initialState = {
  joinState: false,
  remoteUsers: [],
  cameraId: null,
  microphoneId: null,
  videoDevices: [],
  audioDevices: [],
  audioId: null,
  videoId: null,
  muting: false,
  networkQuality: 0,
  videoStatus: VideoStatus.none,
};

export interface IVideoContext {
  stream: DocumentSnapshot,
  videoStatus: string;
  localVideoTrack: ICameraVideoTrack;
  localAudioTrack: ILocalAudioTrack;
  remoteVideoTrack: IRemoteVideoTrack;
  joinState: boolean;
  remoteUsers: GenericObject[];
  cameraId: string | null;
  microphoneId: string | null;
  videoDevices: GenericObject[];
  audioDevices: GenericObject[];
  videoClient: IAgoraRTCClient | undefined;
  muting: boolean;
  networkQuality: number;
  startStream: () => Promise<boolean>;
  stopClient: (close?: boolean) => void;
  leave: () => void;
  join: (clientRole: ClientRole) => Promise<UID>;
  getVideoClient: (
    role?: ClientRole | undefined,
    audioId?: string | undefined,
    videoId?: string | undefined
  ) => Promise<IAgoraRTCClient>;
  getVolume: () => number;
  getVideoSettings: () => GenericObject;
  setCameraId: (id: string) => void;
  setMicrophoneId: (id: string) => void;
  toggleMic: (muted?: boolean) => void;
  pauseStreaming: () => void;
  resumeStreaming: (update?: boolean) => void;
  stopStreaming: (leave?: boolean, dest?: string) => void;
  startStatsSession: () => void;
  stopStatsSession: () => void;
}

export enum ClientRoles {
  audience = 'audience',
  host     = 'host',
}

export const VideoContext =
  React.createContext<Partial<IVideoContext>>({});

export const VideoConsumer = VideoContext.Consumer;

export const VideoProvider = ({children}:{children:ReactNode}) => {

  const {
    event, 
    updateEvent,
    hostId,
  } = useEventContext();

  const {
    defaultListenerOptions,
    callable,
    firebaseUser,
  } = useFirebaseContext();

  const {
    eventStreamRef,
    videoSessionRef,
    eventPrivateStatsRef,
  } = useRefs();

  const {
    userProfile,
  } = useSessionContext();

  const [stream] = useDocument(
    eventStreamRef!(userProfile?.orgId === event?.get('orgId') ? event?.id || 'x' : 'x_x_x'),
    defaultListenerOptions,
  )

  const [
    {
      joinState,
      remoteUsers,
      cameraId,
      microphoneId,
      videoDevices,
      audioDevices,
      muting,
      networkQuality,
      videoStatus = VideoStatus.none,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const statsUserId = useRef<string>(`guest_${nanoid(10)}`);
  const statsTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const statsSessionId = useRef<string | null>(null);
  const clientRef = useRef<IAgoraRTCClient | undefined>(undefined);
  const connectingFlag = useRef<boolean>(false);

  const localVideoTrack = useRef<ICameraVideoTrack | undefined>(undefined);
  const localAudioTrack = useRef<ILocalAudioTrack | undefined>(undefined);
  const remoteVideoTrack = useRef<IRemoteVideoTrack | undefined>(undefined);
  const remoteAudioTrack = useRef<IRemoteAudioTrack | undefined>(undefined);

  let history = createBrowserHistory(window);

  useEffect(() => {
      return () => {
        history.listen(async ({ location, action }) => {
          debug('Leaving at history effect', action, clientRef?.current?.connectionState, videoStatus);
          await cleanup();
        });
        debug('Calling cleanup on unmount');
        cleanup();
      }
  },[]);

  useEffect(() => {
    (async () => {
      if (!firebaseUser) return;
      restartStatsSession(firebaseUser.uid);
      statsUserId.current = firebaseUser.uid;
    })();
  }, [firebaseUser]);

  useEffect(() => {
    debug('Track effect', localVideoTrack?.current?.getMediaStreamTrack()?.getSettings()?.deviceId, localAudioTrack?.current?.getMediaStreamTrack()?.getSettings()?.deviceId)
    if (localAudioTrack.current) {
      setMicrophoneId(
        localAudioTrack.current?.getMediaStreamTrack()?.getSettings()?.deviceId || ''
      );
    }
    if (localVideoTrack.current) {
      setCameraId(localVideoTrack.current?.getMediaStreamTrack()?.getSettings()?.deviceId || '');
    }
  }, [localVideoTrack, localAudioTrack]);

  useEffect(() => {
    (async () => {
      if (!localAudioTrack) return;
      try {
            await localAudioTrack?.current?.setMuted(muting);
      } catch (err) {}
    })()
  }, [muting]);

  useEffect(() => {
    (async () => {
      if (!getVideoClient || !event?.exists() || !userProfile || clientRef?.current) {
        return;
      }
      if ((userProfile.orgId === event.get('orgId') || event.get('stream.provider') === VideoProviderTypes.kwixl) && !clientRef.current) {
        await getVideoClient();
      }
    })();
  },[event, userProfile]);

  useEffect(() => {
    debug('Videoprovider stream effect', stream?.data());
    if (!stream?.exists()) return;
    dispatch({ videoStatus: stream.get('status') || VideoStatus.none });
  },[stream]);

  const cleanup = async () => {
    if (localVideoTrack.current) 
      await stopClient(true);
    else 
      await leave();
  }

  const handleNetworkQuality = (stats: GenericObject) => {
    dispatch({networkQuality: stats.uplinkNetworkQuality});
  };

  const playAudio = () => {
    const el = document.getElementById('play-button') as HTMLButtonElement;
    if (el) el.click();
  }

  const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    debug('User published track', mediaType, user);
    await clientRef!.current!.subscribe(user, mediaType);
    // toggle rerender while state of remoteUsers changed.
    //setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    switch (mediaType) {
      case 'video':
        remoteVideoTrack.current = user.videoTrack;
        // start stats session for viewer
        startStatsSession();
        break;
      case 'audio':
        remoteAudioTrack.current = user?.audioTrack;
        playAudio();
        break;
      default:
        break;
    }
    dispatch({ remoteUsers: Array.from(clientRef.current?.remoteUsers || [])});
  };

  const handleUserUnpublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    debug('User unpublish track', mediaType, user);
    //setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    try {
      await clientRef!.current!.unsubscribe(user, mediaType);
    } catch (err:any) {
      console.error(`Error unsubscribing from track ${mediaType}: ${err.message}`);
    }
    switch (mediaType) {
      case 'video':
        remoteVideoTrack.current = undefined;
        break;
      case 'audio':
        try {
          if (remoteAudioTrack.current) remoteAudioTrack.current.stop();
        } catch (err: any) {
          console.log(`Error stopping remote audio track: ${err.message}`);
        }
        remoteAudioTrack.current = undefined;
        break;
    }
    dispatch({
      remoteUsers: Array.from(clientRef.current?.remoteUsers || []), 
    });
    // stopStatsSession for viewer
    stopStatsSession();
  };

  const handleLiveStreamingError = (url: string, error: any) => {
    console.error(error);
    stopStreaming();
  };

  const handleConnectionStateChange = async (curState: string, revState: string, reason: string) => {
    debug('Connection status change', 'State: ' + curState + ' revState: ' + revState + ' reason: ' + reason);
    switch (curState) {
      case 'DISCONNECTED':
        
        break;
      case 'CONNECTED':
        //dispatch({videoStatus: VideoStatus.started});
        break;
    }
    // "DISCONNECTED" | "CONNECTING" | "RECONNECTING" | "CONNECTED" | "DISCONNECTING"
    // can also call connectionState to get current connection state
  };

  const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
    //setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    debug(`User ${user.uid} has joined`);
    dispatch({
      remoteUsers: Array.from(clientRef.current?.remoteUsers || [])
    });
  };

  const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
    debug(`User ${user.uid} has left`);
    //setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    dispatch({
      remoteUsers: Array.from(clientRef.current?.remoteUsers || [])
    });
  };

  const handleTokenWillExpire = async () => {
    const {data} = await callable!('video-token', {
      action: 'token',
      eventId: event?.id,
    });
    const {token} = data;
    await clientRef.current?.renewToken(token);
  };

  // Adds/removes listeners to video client
  const setClientListeners = () => {

    if (!clientRef.current) {
      return;
    }

    clientRef.current?.on('user-published', handleUserPublished);
    clientRef.current?.on('user-unpublished', handleUserUnpublished);
    clientRef.current?.on('user-joined', handleUserJoined);
    clientRef.current?.on('user-left', handleUserLeft);
    clientRef.current?.on('token-privilege-will-expire', handleTokenWillExpire);
    clientRef.current?.on('network-quality', handleNetworkQuality);
    clientRef.current?.on('connection-state-change', handleConnectionStateChange);
    clientRef.current?.on('live-streaming-error', handleLiveStreamingError);
    dispatch({
      remoteUsers: clientRef.current?.remoteUsers || [],
    });
  };

  const getVolume = (): number => localAudioTrack.current?.getVolumeLevel() || 0;

  // Sets up camera and microphone devices and gets an Agora client
  const getVideoClient = async (
    audioId = '',
    videoId = ''
  ): Promise<IAgoraRTCClient> => {

    if (clientRef.current) {
      debug('Video client already initialized', clientRef.current.connectionState);
      return clientRef.current;
    }

    debug('Get video client');

    try {
      if (process.env.REACT_APP_STAGE === 'production') {
        // disable SDK logging in prod
        AgoraRTC.setLogLevel(4);
      } else {
        AgoraRTC.enableLogUpload();
        AgoraRTC.setLogLevel(0);
      }
    } catch (err) {}

    let cameras: any[] = [];
    let microphones: any[] = [];

    if (userProfile && event?.exists() && userProfile?.orgId === event?.get('orgId')) {
      //if (!validDeviceId(audioId)) {
      audioId = '';
      //}

      //if (!validDeviceId(videoId)) {
      videoId = '';
      //}

      const devices = await AgoraRTC.getDevices();

      const microphones = devices.filter(function (device) {
        return device.kind === 'audioinput' && device.deviceId !== 'default';
      });

      cameras = devices.filter(function (device) {
        return device.kind === 'videoinput' && device.deviceId !== 'default';
      });

      const mobile = checkMobile();

      // get just the front and back camera...some phones have multiple cameras
      if (mobile) {
        // get front facing camera
        const front = cameras.filter(({label}) => {
          const l = label.toLowerCase();
          return l.includes('front') || l.includes('user');
        });
        const rear = cameras.filter(({label}) => {
          const l = label.toLowerCase();
          return l.includes('back') || l.includes('rear') || l.includes('environment');
        });
        // get just 2 cameras to use for switching back and forth
        let frontCamera = front.filter(camera => camera.deviceId === videoId);
        if (!frontCamera.length) {
          frontCamera = front[0];
        }
        let backCamera = rear.filter(camera => camera.deviceId === videoId);
        if (!backCamera.length) {
          backCamera = rear[0];
        }
        cameras = [frontCamera, backCamera];
      }

      if (audioId) {
        // verify audio device exists if specified
        let check: GenericObject[] = microphones.filter(
          ({deviceId}) => deviceId === audioId
        );
        if (!check.length && microphones.length > 0)
          audioId = microphones[0].deviceId;
      }

      if (videoId) {
        // verify audio device exists if specified
        let check = cameras.filter(({deviceId}) => deviceId === videoId);
        if (!check.length && cameras.length > 0) videoId = cameras[0].deviceId;
      }

      if (mobile) {
        videoId = '';
      }

      const encoderOptions: GenericObject = {
        encoderConfig: '720p_2',
        optimizationMode: 'motion',
      };

      if (mobile) {
        encoderOptions.facingMode = 'environment';
      }

      const [,cameraTrack] = await createLocalTracks(encoderOptions);

      // not really necessary?
      if (cameraTrack && mobile) {
        cameras.push({
          deviceId: cameraTrack.getMediaStreamTrack().getSettings().deviceId,
          label: cameraTrack.getMediaStreamTrack().label,
          facingMode: 'environment'
        })
      }
    }

    debug('Creating new video client');
    clientRef.current = AgoraRTC.createClient({mode: 'live', codec: 'vp8'});

    clientRef.current.setClientRole(ClientRoles.audience);

    dispatch({
      cameraId: videoId,
      microphoneId: audioId,
      videoDevices: cameras || [],
      audioDevices: microphones || [],
    });

    return clientRef.current;

  };

  const toggleMic = (muted?: boolean) => {
    dispatch({muting: !!muted ? muted : !muting});
  };

  const pauseStreaming = async () => {
    toggleMic(true);
    await localVideoTrack.current?.setEnabled(false);
    await updateDoc(eventStreamRef!(event?.id || ''), {
      status: VideoStatus.paused,
    });
  };

  const resumeStreaming = async (update = true) => {
    toggleMic(false);
    await localVideoTrack.current?.setEnabled(true);
    if (update) {
      await updateDoc(eventStreamRef!(event?.id || ''), {
        status: VideoStatus.started,
      });
    }
  };

  const stopStreaming = async (leave = false, dest?: string) => {
    await stopStatsSession();
    await updateDoc(eventStreamRef!(event?.id || ''), {
      status: VideoStatus.stopped,
    });
    await updateEvent({ presenterId: null, hostId: null });
    await stopClient(leave);
  };

  const getVideoSettings = (): GenericObject => {
    return localVideoTrack?.current?.getMediaStreamTrack().getSettings() || {};
  };

  const createLocalTracks = async (
    videoConfig?: CameraVideoTrackInitConfig
  ) => {
    debug('create local tracks')
    if (localVideoTrack.current && localAudioTrack.current) {
      debug('Returning existing tracks');
      return [localAudioTrack.current, localVideoTrack.current];
    }
    if (!localAudioTrack.current) {
      debug('Create microphone track');
      localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
    }
    if (!localVideoTrack.current) {
      debug('Create camera track');
      localVideoTrack.current = await AgoraRTC.createCameraVideoTrack(videoConfig);
    }
    return [localAudioTrack.current, localVideoTrack.current];
  };

  const join = async (clientRole: ClientRole): Promise<UID> => {

    if (!clientRef.current) await getVideoClient();

    if (!clientRef.current) {
      flash.error('Could not join live stream: no video client available.');
      return 0;
    }

    if (['CONNECTED', 'CONNECTING'].includes(clientRef.current.connectionState)) return clientRef.current.uid || 0;

    if (clientRole === ClientRoles.host && (!localAudioTrack.current || !localVideoTrack.current)) {
      debug('Calling create local tracks');
      await createLocalTracks();
    }

    debug('Get video token')
    const {data, error} = await callable!('video-token', {
      action: 'token',
      eventId: event?.id,
    });

    debug('Video token result', data, error);
    
    const {token, channel, channelUserId} = data || {};

    if (!token || !channelUserId) {
      flash.error(`Error joining channel!`)
      return 0;
    }

    const uid = await clientRef.current.join(
      process.env.REACT_APP_AGORA_ID || 'x',
      channel,
      token,
      channelUserId
    );

    await clientRef.current.setClientRole(clientRole);

    debug('joined ' + channel + ' as ' + clientRole, 'with id', channelUserId);

    if (clientRole === ClientRoles.host) {
      debug('Publishing local tracks');
      if (localAudioTrack.current)
        await clientRef.current.publish([localAudioTrack.current]);
      if (localVideoTrack.current)
        await clientRef.current.publish([localVideoTrack.current]);
    }

    setClientListeners();

    if (clientRole === ClientRoles.audience) {
      debug('Remote users', clientRef.current.remoteUsers);
      const host = clientRef.current.remoteUsers.find(u => u.hasVideo);
      if (host) {
        handleUserPublished(host, 'video');
        handleUserPublished(host, 'audio');
      }
    }
    dispatch({ 
      joinState: true,
      remoteUsers: clientRef.current.remoteUsers,
    });

    return uid;

  };

  // leave room
  const leave = async () => {
    debug('Leaving channel');
    if (remoteVideoTrack.current) {
      try {
        remoteVideoTrack?.current.stop();
      } catch (err: any) {
        console.log('Error stopping remote video', err.message);
      }
    }

    if (remoteAudioTrack.current) {
      try {
        remoteAudioTrack.current.stop();
      } catch (err: any) {
        console.log('Error stopping remote audio', err.message);
      }
    }

    try {
      clientRef.current?.off('user-published', handleUserPublished);
      clientRef.current?.off('user-unpublished', handleUserUnpublished);
      clientRef.current?.off('user-joined', handleUserJoined);
      clientRef.current?.off('user-left', handleUserLeft);
      clientRef.current?.off('token-privilege-will-expire', handleTokenWillExpire);
      clientRef.current?.off('network-quality', handleNetworkQuality);
      clientRef.current?.off('connection-state-change', handleConnectionStateChange);
      clientRef.current?.off('live-streaming-error', handleLiveStreamingError);
    } catch (err: any) {
      console.error(`Error removing listeners: ${err.message}`);
    }

    try {
      await clientRef.current?.leave();
    } catch (err: any) {
      console.log(`Error leaving channel`, err.message);
    }
    remoteVideoTrack.current = undefined;
    remoteAudioTrack.current = undefined;
    dispatch({
      remoteUsers: [],
      joinState: false,
    });
  };

  const stopClient = async (close = false) => {
    if (localAudioTrack?.current) {
      try {
        if (close) { 
          localAudioTrack.current.stop();
          localAudioTrack.current.close();
        }
      } catch (err: any) {
        console.log('Error shutting down local audio track', err.message);
      }
    }
    if (localVideoTrack?.current) {
      try {
        if (close) {
          localVideoTrack.current.stop();
          localVideoTrack.current.close();
        }
      } catch (err: any) {
        console.log('Error shutting down local video track', err.message);
      }
    }
    try {
      if (localAudioTrack.current)
        await clientRef.current?.unpublish([localAudioTrack.current]);
      if (localVideoTrack.current)
        await clientRef.current?.unpublish([localVideoTrack.current]);
    } catch (err: any) {
      console.log('Error unpublishing track', err.message);
    }
    await leave();
    dispatch({networkQuality: 0, videoStatus: VideoStatus.stopped});
    return true;
  };

  const setMicrophoneId = (id: string) => dispatch({microphoneId: id});

  const setCameraId = (id: string) => dispatch({cameraId: id});

  const updateStats = async () => {
    if (!statsSessionId.current || !clientRef.current) return;
    const stats = clientRef.current.getRTCStats();
    await setDoc(videoSessionRef!(event?.id || '', statsSessionId.current), {
      stats,
      createdAt: serverTimestamp(),
      uid: firebaseUser?.uid || statsUserId.current || 'guest',
      eventId: event?.id,
      role: clientRef.current.role || ClientRoles.audience,
    });
  }

  const createStatsSession = async (uid?: string) => {
    if (!clientRef.current) return;
    const id = nanoid(10);
    statsSessionId.current = id;
    await updateStats();
    // set time to update every 30 seconds
    if (statsTimer.current) clearInterval(statsTimer.current);
    statsTimer.current = setInterval(updateStats, 30000);
  };

  const restartStatsSession = async (uid?: string) => {
    if (!clientRef.current) return;
    await stopStatsSession();
    await createStatsSession(uid);
  };

  /**
   * 1. If host, make sure stats doc exists
   * 2. Create session document for user
   */
  const startStatsSession = async (uid?: string) => {
    if (event?.get('hostId') === hostId && firebaseUser?.uid === statsUserId.current) {
      const stats = await getDoc(eventPrivateStatsRef!(event?.id || 'x'));
      if (!stats.exists()) {
        await setDoc(eventPrivateStatsRef!(event?.id || ''), {
          createdAt: serverTimestamp(),
          eventId: event?.id,
        });
      }
    }
    await createStatsSession(uid);
  };

  const stopStatsSession = async () => {
    if (statsSessionId.current) {
      if (statsTimer.current) clearInterval(statsTimer.current);
      try {
        let update: GenericObject = {
          endedAt: serverTimestamp(),
        };
        if (clientRef.current)
          update.stats = await clientRef.current.getRTCStats();
        await updateDoc(
          videoSessionRef!(event?.id || '', statsSessionId.current),
          update
        );
      } catch (err: any) {
        console.log('Error stopping session', err.message);
      }
    }
    statsSessionId.current = null;
  };

  const startStream = async (): Promise<boolean> => {
    debug('Starting video stream');
    // 1. Get token (using event ID as room name)
    if (!clientRef.current) {
      flash.error('No video client!');
      return false;
    }
    if (connectingFlag.current === true || ['CONNECTING','RECONNECTING'].includes(clientRef.current.connectionState)) {
      debug('Client already in connecting state')
      return false;
    }
    if (clientRef.current.connectionState === 'DISCONNECTING') {
      debug('Client is already in disconnecting state');
      return false;
    }
    connectingFlag.current = true;
    try {
      if (clientRef.current.connectionState === 'CONNECTED') {
        debug('Client already connected');
        try {
          await clientRef.current?.leave();
        } catch (err: any) {
          console.log('Error leaving previous room', err.message);
        }
      }
    } catch (err) {}
    try {
      const uid = await join(ClientRoles.host);
      if (uid && [VideoProviderTypes.youtube, VideoProviderTypes.facebook].indexOf(stream?.get('provider')) >= 0) {
        let url;
        let {height, width} = getVideoSettings();
        let config: LiveStreamingTranscodingConfig = {
          width,
          height,
          videoBitrate: 2000,
          videoFrameRate: 30,
          audioSampleRate: 48000, //AgoraRTC.AUDIO_SAMPLE_RATE_48000,
          audioBitrate: 48,
          audioChannels: 1,
          videoCodecProfile: 100, //AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
          backgroundColor: 0x000000,
          transcodingUsers: [
            {
              uid,
              alpha: 1,
              width,
              height,
              zOrder: 1,
              x: 0,
              y: 0,
            },
          ],
        };
        /*
        if (event?.get('type') === EventType.ccs) {
          const background = cdnImage('/overlays/fb_overlay.png', true);
          if (background) {
            config.watermark = {
              alpha: 1,
              height: Math.round(height * 0.05),
              width,
              x: 0,
              y: Math.round(height * 0.95),
              url: background,
            };
          }
        }
        */
        switch (stream?.get('provider')) {
          case VideoProviderTypes.youtube:
            url =
              stream?.get('ingestUrl') ||
              `rtmp://a.rtmp.youtube.com/live2/${stream?.get('streamKey')}`;
            break;
          case VideoProviderTypes.facebook:
            url =
              stream?.get('ingestUrl') ||
              `rtmps://live-api-s.facebook.com:443/rtmp/${stream?.get('streamKey')}`;
            break;
        }
        // only setup CDN transcoding if the video is being broadcast
        // to a third-party
        if (url) {
          await clientRef.current.setLiveTranscoding(config);
          await clientRef.current.startLiveStreaming(url, true);
        }
      }
      await updateEvent({ presenterId: firebaseUser?.uid, hostId });
      await updateDoc(eventStreamRef!(event?.id || ''), {
        status: VideoStatus.started,
      });
      await startStatsSession();
      connectingFlag.current = false;
      return true;
    } catch (err: any) {
      console.log(err);
      flash.error('ERROR STARTING STREAM: ' + err.message);
      dispatch({videoStatus: VideoStatus.none});
    }
    connectingFlag.current = false;
    return false;
  };

  debug(
    'VideoProvider: Client ->', 
    clientRef.current ? 'initialized' : undefined,  
    'State:', clientRef.current?.connectionState,
    'Remote track ->', remoteVideoTrack?.current ? 'initialized' : undefined
  );

  return (
    <VideoContext.Provider
      value={{
        localAudioTrack: localAudioTrack.current,
        localVideoTrack: localVideoTrack.current,
        remoteVideoTrack: remoteVideoTrack.current,
        stream,
        joinState,
        remoteUsers,
        videoClient: clientRef.current,
        videoDevices,
        audioDevices,
        cameraId,
        microphoneId,
        muting,
        networkQuality,
        videoStatus,
        startStream,
        leave,
        join,
        getVideoClient,
        getVolume,
        getVideoSettings,
        setCameraId,
        setMicrophoneId,
        toggleMic,
        pauseStreaming,
        resumeStreaming,
        stopClient,
        stopStreaming,
        startStatsSession,
        stopStatsSession,
      }}
    >
      {children}
      <Box display="none">
        <Button id="play-button" onClick={() => remoteAudioTrack.current?.play()}/>
      </Box>
    </VideoContext.Provider>
  );
};
