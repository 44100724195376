import styled from '@emotion/styled';
import {ROUTES} from 'routes';
import {useSessionContext} from 'hooks';
import {Logo, InternalLink, ExternalLink} from 'components';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const SiteTitle = () => {

    const {channel} = useSessionContext();

    return (
      <InternalLink to={ROUTES.home} color="inherit">
        <Grid container columns={2} spacing={2}>
          <Grid item sx={{ paddingTop: '15px !important' }}>
            <Logo inline/>
          </Grid>
          <Grid item sx={{
              paddingTop: '6px',
              verticalAlign: 'middle',
              display: { xs: 'none', md: 'initial'}
            }}
          >
            <Box mt={1} sx={{ textTransform: 'uppercase' }}>{ process.env.REACT_APP_NAME } <Bold>Social</Bold> Tools</Box>
            {channel?.name && (
              <ExternalLink  color="inherit" to={`https://${channel?.id}.myshopify.com/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_SLUG || 'kwixl-sales-channel'}/shop/account`}>
                <ChannelName>
                  <RssFeedIcon/> {channel.name}
                </ChannelName>
              </ExternalLink>
            )}
            {/*partners.includes('shopify') && 
              <Shopify>
                <CdnImage src="/img/partners/shopify.png"/>
              </Shopify>
            */}
          </Grid>
        </Grid>  
      </InternalLink>  
    )

}

const ChannelName = styled.span`
  display: block;
  font-weight: 300;
  margin-top: .5em;
`

const Bold = styled.span`
  font-weight: 500;
`;
