import {createContext, useReducer, useEffect, useRef, ReactNode} from 'react';
import { reducer, debug } from "lib";
import { useFirebaseContext, useRefs, useSessionContext } from 'hooks';
import {
  where, 
  query, 
  setDoc,
  Timestamp,
  getCountFromServer,
} from 'firebase/firestore';

const initialState = {
  notificationCount: 0,
  messageCount: 0,
}

interface IMessaging {
  notificationCount: number;
  messageCount: number;
  getUnreadMessages: () => Promise<void>;
  deleteNotification: (id: string) => Promise<void>;
}

export const MessagingContext = createContext<Partial<IMessaging>>(initialState);

export const MessagingProvider = ({children}:{ children: ReactNode }) => {

  const [{
    notificationCount,
    messageCount,
  }, dispatch] = useReducer(reducer, initialState);

  const { 
    firebaseUser,
  } = useFirebaseContext();

  const {
    messagesRef, 
    notificationsRef,
    notificationRef,
  } = useRefs();

  const {organization} = useSessionContext();

  let unsubMessages = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    if (!firebaseUser || !organization) return;
    // Get notification and message counts
    getUnreadMessages();
    unsubMessages.current = setInterval(getUnreadMessages, 30000);
    return () => {
      clearInterval(unsubMessages.current);
    }
  },[firebaseUser, organization]);

  const getUnreadMessages = async () => {
    let messages_count = 0;
    let notifications_count = 0;
    if (organization) {
      let results = await getCountFromServer(
        query(
          messagesRef!, 
          where('org.id', '==', organization.id),
          where('orgUnread', '>', 0),
        )
      );
      messages_count += results.data().count;
    }
    if (firebaseUser) {
      let results = await getCountFromServer(
        query(
          messagesRef!,
          where('user.id', '==', firebaseUser.uid),
          where('userUnread', '>', 0),
        )
      );
      messages_count += results.data().count;
      results = await getCountFromServer(
        query(
          notificationsRef!, 
          where("uid", "==", "" + (firebaseUser?.uid || 0)),
          where("readAt", "==", null),
        )
      );
      notifications_count = results.data().count;
    }
    dispatch({
      messageCount: messages_count,
      notificationCount: notifications_count,
    });
  }

  const deleteNotification = async (id: string) => setDoc(notificationRef!(id), { deletedAt: Timestamp.now() }, { merge: true });

  debug('Messaging provider');

  return (
    <MessagingContext.Provider value={{
      notificationCount,
      getUnreadMessages,
      messageCount,
      deleteNotification,
    }}>
      {children}
    </MessagingContext.Provider>
  );
};
