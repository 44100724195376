export const CreateEventModal = () => <></>;

/*
import {useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {flash, reducer, validate, debug} from 'lib';
import {edit_event_schema} from 'schema';
import {customAlphabet} from 'nanoid';
import {DateTime} from 'luxon';
import {
  setDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {ROUTES} from 'routes';
import {EventType} from '@kwixl/interface';
import {
  EventDetailsForm, 
  CalloutButton, 
  GenericObject, 
  CheckButton, 
  CancelButton
} from 'components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

interface Props {
  onClose?: () => void;
  trigger?: any;
}

const initialState = {
  open: false,
  event: {type: EventType.kwixl},
  errors: {},
  loading: false,
};

export const CreateEventModal = ({
  onClose = () => {},
}: Props) => {
  const navigate = useNavigate();

  const {
    firebaseUser, 
  } = useFirebaseContext();

  const {
    organizationSettingsRef, 
    eventRef,
  } = useRefs();

  const {
    channel,
    mobile,
    organization,
  } = useSessionContext();

  const [{open, event, errors, loading}, dispatch] = useReducer(
    reducer,
    initialState
  );

  const toggleOpen = () => dispatch({open: !open});

  const handleChange = (name = '', value = '') => {
    delete errors[name];
    dispatch({event: {...event, [name]: value}, errors});
  };

  const handleSave = async () => {

    dispatch({loading: true});

    debug('Create Event -> validate:', event);
    
    let formErrors = validate(event, edit_event_schema);

    if (formErrors) {
      debug('Create Event Errors:', formErrors);
      dispatch({loading: false, errors: formErrors});
      return;
    }

    const startAt = event?.startAt 
        ? DateTime.fromJSDate(event?.startAt).toUTC()
        : DateTime.utc()
    const endAt = startAt.plus({ hours: 4 });
    const settings = await getDoc(organizationSettingsRef!(organization?.id || 'x'));
    try {
        const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 6);
        const eventId = nanoid();
        const update: GenericObject = {
          ...event,
          startAt: startAt.toJSDate(),
          endAt: endAt.toJSDate(),
          status: 'pending',
          published: false,
          image: null,
          orgId: organization?.id,
          deletedAt: null, 
          createdBy: firebaseUser?.uid,
          createdAt: serverTimestamp(),
          settings: settings.data() || {},
        }
        if (channel?.type) {
          update.channel = {
            id: channel.type,
            name: channel.type,
            shop: channel.id,
          }
        }
        await setDoc(
          eventRef!(eventId || 'x'), 
          update,
        );
      flash.success('Event created!');
      navigate(ROUTES.cp_kwixllive_event_edit(eventId));
      handleClose();
    } catch(err: any) {
      flash.error(`Error creating event: ${err.message}`);
    }
    dispatch({loading: false});
  };

  const handleClose = () => {
    dispatch({open: false});
    onClose();
  };

  const Trigger = () => (
    <CalloutButton size={mobile ? 'small' : 'medium'} disabled={open} startIcon={<AddIcon/>} onClick={() => dispatch({ open: true })}>
      Event
    </CalloutButton>
  )

  return (
    <>
    <Trigger/>
    <Dialog
      onClose={toggleOpen}
      open={open}
      scroll='paper'
    >
      <DialogTitle>Create Live Event</DialogTitle>
      <DialogContent dividers>
        <Box mt={2}>
          <EventDetailsForm onChange={handleChange} errors={errors} />
        </Box>
      </DialogContent>
      <DialogActions>
        <CancelButton disabled={loading} onClick={() => handleClose()}/>
        <CheckButton
          disabled={loading}
          loading={loading}
          onClick={() => handleSave()}
        >
          Create Event
        </CheckButton>
      </DialogActions>
    </Dialog>
    </>
  );
};
*/