import {
  useReducer,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
  ReactNode,
} from 'react';
import {
  ref as getStorageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import {updateDoc} from 'firebase/firestore';
import {reducer, flash, debug} from 'lib';
import styled from '@emotion/styled';
import {useSessionContext, useFirebaseContext, useRefs} from 'hooks';
import Cropper from 'react-easy-crop';
import {getCroppedImg} from './components';
import {GenericObject, CancelButton} from 'components';
import {cdnImage} from '../';
import {customAlphabet} from 'nanoid';
import { Modal } from 'modals';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 16);

interface Dimensions {
  width: number;
  height: number;
}

const initialState = {
  imageUrl: null,
  crop: {x: 0, y: 0},
  progress: 0,
  uploading: false,
  zoom: 1,
  rotation: 0,
  croppedAreaPixels: null,
  saving: false,
  cameraStream: null,
  cameraOpen: false,
  currentFile: null,
};

interface ImageUploaderProps {
  subHeader?: string;
  open?: boolean;
  header?: any;
  path?: string;
  trigger?: any;
  dest?: GenericObject | undefined;
  cancelButton?: boolean;
  onSuccess?: (downloadUrl: string) => void;
  onClose?: () => void;
  onPrepare?: (data: GenericObject) => void;
  onError?: (error: GenericObject) => void;
  cropSize?: {width: number; height: number};
  cropShape?: 'rect' | 'round';
  aspect?: number;
  useCamera?: boolean;
  children?: ReactNode;
}

export const ImageUploader = (props: ImageUploaderProps) => {

  const {
    header = 'Upload Image',
    dest,
    cancelButton = true,
    aspect = 1,
    cropShape,
    cropSize,
    useCamera,
    trigger,
    open = false,
    onSuccess = () => {},
    onClose = () => {},
  } = props;

  const {mobile, orientation} = useSessionContext();
  const {getCdn} = useFirebaseContext();

  const hiddenVideoRef = useRef<HTMLVideoElement>(null);
  const cameraCanvasRef = useRef<HTMLCanvasElement>(null);
  const videoContainerRef = useRef<any>(null);
  const cropRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [
    {
      imageUrl,
      crop,
      progress,
      uploading,
      zoom,
      rotation,
      croppedAreaPixels,
      saving,
      cameraStream,
      currentFile,
      cameraOpen,
      active = false,
    },
    dispatch,
  ] = useReducer(reducer, { ...initialState, active: open });

  useEffect(() => {
    reset();
    return () => {
      closeCamera();
    };
  }, []);

  useEffect(() => {
    dispatch({ active: open });
  },[open]);

  useEffect(() => {
    if (cameraStream) {
      closeCamera();
      getCamera();
    }
  }, [orientation]);

  const handleClose = () => {
    dispatch({ active: false });
    onClose();
  }

  const reset = () => {
    dispatch(initialState);
  };

  const cancel = () => {
    closeCamera();
    reset();
    dispatch({ active: false });
    handleClose();
  };

  const readFile = (file: Blob) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      dispatch({currentFile: file, imageUrl: imageDataUrl});
    }
  };

  const closeCamera = async () => {
    try {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      }
      videoRef?.current?.pause()
      hiddenVideoRef?.current?.pause()
      //videoContainerRef.current.style.display = 'none';
      //cropRef.current.style.display = 'block';
    } catch (err) {
      console.error(err);
    }
    dispatch({cameraOpen: false});
  };

  const getCamera = async () => {
    const options = {
      audio: false,
      video:
        mobile && orientation === 'portrait'
          ? {
              width: {
                min: 480,
                ideal: 720,
                max: 720,
              },
              height: {
                min: 640,
                ideal: 720,
                max: 1280,
              },
            }
          : {
              height: {
                min: 480,
                ideal: 720,
                max: 720,
              },
              width: {
                min: 640,
                ideal: 720,
                max: 1280,
              },
            },
      facingMode: 'environment',
    };
    const stream = await navigator.mediaDevices.getUserMedia(options);
    const videoTracks = stream.getVideoTracks();
    const settings = videoTracks[0].getSettings();
    const {width = 0, height = 0} = settings;
    const dimensions: Dimensions = {width, height};
    dispatch({cameraStream: stream});
    return {stream, dimensions};
  };

  const openCamera = async () => {
    //reset();
    dispatch({currentFile: null, imageUrl: null, cameraOpen: true});
    try {
      if (cropRef.current) {
        cropRef.current.style.display = 'none';
      }

      let width: number, height: number;

      if (!cameraStream) {
        const {stream, dimensions} = await getCamera();
        width = dimensions.width;
        height = dimensions.height;
        videoRef.current!.srcObject = stream;
        hiddenVideoRef.current!.srcObject = stream;
      } else {
        const settings = cameraStream.getVideoTracks()[0].getSettings();
        width = settings.width;
        height = settings.height;
        videoRef.current!.srcObject = cameraStream;
        hiddenVideoRef.current!.srcObject = cameraStream;
      }

      // show camera playback container (sized image)
      videoContainerRef.current!.style.display = 'block';
      videoRef.current!.style.marginLeft = `-${(width - 300) / 2}`;
      videoRef.current!.style.marginTop = `-${(height - 300) / 2}`;
      await videoRef.current!.play();

      // hidden video for full-size image used for picture capture
      hiddenVideoRef.current!.style.width = `${width}px`;
      hiddenVideoRef.current!.style.height = `${height}px`;
      await hiddenVideoRef.current!.play();
    } catch (err) {
      console.error(err);
      flash.error('Error opening camera');
    }
  };

  const getCameraSize = () => {
    if (cameraStream) {
      const settings = cameraStream.getVideoTracks()[0].getSettings();
      return [settings.width, settings.height];
    } else {
      return [0, 0];
    }
  };

  const takePicture = async () => {
    const [width, height] = getCameraSize();

    const h = width < height ? width : height < width ? height : width;

    cameraCanvasRef.current!.width = h;
    cameraCanvasRef.current!.height = h;

    let x = (width - h) / 2;

    let y = (height - h) / 2;

    // have to crop the video stream or the image is skewed
    if (hiddenVideoRef.current)
      cameraCanvasRef.current?.getContext('2d')?.drawImage(
        hiddenVideoRef.current as CanvasImageSource,
        x,
        y, // x/y offset of source image
        h,
        h,
        0,
        0,
        h,
        h
      );

    try {
      const el = document.getElementById('camera-shutter') as HTMLMediaElement;
      el.play();
    } catch (err) {}

    const url = cameraCanvasRef.current?.toDataURL('image/jpeg', 1.0);

    dispatch({imageUrl: url});
    closeCamera();
  };

  const openFileUpload = () => {
    closeCamera();
    reset();
    buttonRef.current?.click();
  };

  const onCropComplete = useCallback((croppedAreaPercentages: any, croppedAreaPixels: any) => {
    dispatch({croppedAreaPixels});
  },[]);

  const getContentType = (ext: string) => {
    switch (ext) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'webp':
        return 'image/webp';
      default:
        return null;
    }
  };

  const saveCroppedImage = useCallback(async () => {
    dispatch({saving: true});

    let croppedImage;

    try {
      croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, rotation);
      dispatch({croppedImage});
    } catch (err: any) {
      flash.error(`There was an error processing your image: ${err.message}`);
      console.error(err);
    }

    let file = await fetch(croppedImage).catch((err: any) => {
      flash.error(`Error saving image: ${err.message}`);
      return;
    });

    if (!file) {
      flash.error(`Error saving image`);
      return;
    }
    //const fileData = new FormData();
    //fileData.append('file', file);
    //const data = handlePrepare(fileData);
    dispatch({uploading: true});

    // Upload here
    try {
      let contentType = 'image/jpeg';
      let ext = 'jpg';

      let destName = dest?.name || '';

      debug('Saving image to', destName);

      if (!destName && currentFile) {
        const [fname, ext] = currentFile.name.split('.');
        destName = fname || '';
        contentType = getContentType(ext) || '';
      }

      const filePath = `${dest?.path || 'misc'}/${destName || nanoid()}.${ext}`;

      debug('File path', filePath);

      let storage = getCdn!();

      let storageRef = getStorageRef(
        storage,
        filePath,
      );

      const uploadTask = uploadBytesResumable(storageRef, await file.blob(), {
        contentType,
      });

      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          dispatch({
            progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          });
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        },
        error => {
          console.log(error);
          // Handle unsuccessful uploads
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            const url = cdnImage(`/${filePath}`);
            onSuccess(url || '');
            reset();
            handleClose();
          });
        }
      );
    } catch (err: any) {
      flash.error(`Error uploading file: ${err.message}`);
      console.error(err.message);
      cancel();
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Modal
      trigger={trigger}
      open={active}
      opened={() => dispatch({ active: true })}
      closed={handleClose}
      title={header || ''}
      fullWidth
      maxWidth='sm'
    >
      <DialogContent dividers>
        <img id="test-image" />
        {uploading ? (
          <LinearProgress value={progress} variant="determinate" />
        ) : (
          <Box>
                Choose a file to upload
                {useCamera && ' or use your camera to take a photo'}. You will
                be able to size and crop the image after upload.
              <Stack direction="row" spacing={2} justifyContent="center" mt={3} mb={2}>
                <Button size="small" variant="outlined" onClick={() => openFileUpload()} startIcon={<CloudUploadOutlinedIcon/>}>
                  Choose File
                </Button>
                {useCamera && (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<VideoCameraFrontOutlinedIcon/>}
                    onClick={() => openCamera()}
                    disabled={cameraStream && !imageUrl}
                  >
                    {' '}{cameraStream ? 'Retake Photo' : 'Use Camera'}
                  </Button>
                )}
              </Stack>
              <input
                id="file-button"
                ref={buttonRef}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                hidden
              />
              {imageUrl && (
                <>
                  <Box 
                    id="cropper-container"
                    ref={cropRef}
                    textAlign='center'
                    position='relative'
                    width='100%'
                    height={'400px'}
                    sx={{
                      backgroundColor: '#333333'
                    }}
                  >
                    <Cropper
                      image={imageUrl}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      cropShape={cropShape}
                      showGrid={false}
                      aspect={aspect}
                      restrictPosition={false}
                      onCropChange={crop => dispatch({crop})}
                      onRotationChange={rotation => dispatch({rotation})}
                      onCropComplete={onCropComplete}
                      onZoomChange={zoom => dispatch({zoom})}
                      cropSize={cropSize}
                    />
                  </Box>
                  <Box 
                    id="cropper-controls-wrapper"
                    display="flex"
                    flexDirection="row"
                  >
                    <Control>
                      <label>Zoom</label>
                      <input
                        type="range"
                        min={0}
                        max={3}
                        step={0.01}
                        value={zoom}
                        onChange={e =>
                          dispatch({zoom: parseFloat(e.target.value)})
                        }
                      />
                    </Control>
                    <Control>
                      <label>Rotate</label>
                      <input
                        type="range"
                        min={0}
                        max={360}
                        step={1}
                        value={rotation}
                        onChange={e =>
                          dispatch({rotation: parseFloat(e.target.value)})
                        }
                      />
                    </Control>
                  </Box>
                </>
              )}
          </Box>
        )}
        {cameraOpen && (
          <CameraContainer id="video-container" ref={videoContainerRef}>
            <VisibleVideo id="visible-video" playsInline ref={videoRef} />
            <CameraButton>
              <IconButton onClick={() => takePicture()} size="large">
                <CameraIcon htmlColor='#fffff0' fontSize="large"/>
              </IconButton>
            </CameraButton>
          </CameraContainer>
        )}
      </DialogContent>

      <DialogActions style={{textAlign: 'center'}}>
        {cancelButton && <CancelButton onClick={cancel} disabled={saving}/>}
        <Button
          variant="outlined"
          onClick={saveCroppedImage}
          loading={saving}
          disabled={!imageUrl || saving}
          startIcon={<CloudUploadOutlinedIcon/>}
        >
          Save
        </Button>
      </DialogActions>
      <audio id="camera-shutter">
        <source src={'/sounds/camera-click.mp3'} type="audio/mp3" />
      </audio>
      <HiddenVideo id="hidden-video" playsInline ref={hiddenVideoRef} />
      <canvas
        ref={cameraCanvasRef}
        id="camera-canvas"
        style={{display: 'none', width: '300px', height: '300px'}}
      />
      <canvas
        ref={previewCanvasRef}
        id="preview-canvas"
        style={{display: 'none'}}
      />
    </Modal>
  );
};

export const ProfileImageUploader = (props: ImageUploaderProps) => {
  const {firebaseUser} = useFirebaseContext();
  const { userProfileRef } = useRefs();
  return (
    <ImageUploader
      {...props}
      aspect={1}
      cropShape="round"
      dest={{
        path: 'profile_images',
        name: firebaseUser?.uid,
      }}
      onSuccess={photoURL => {
        updateDoc(userProfileRef!(firebaseUser?.uid || 'x'), {photoURL});
      }}
      onError={() => {}}
    />
  );
};

const CameraButton = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  left: 48%;
  margin-left: -18px;
  bottom: 15px;
  text-align: center;
  z-index: 10;
`;

const Control = styled.div`
  flex: 1;
  padding: 5px;
  text-align: center;
`;

const CameraContainer = styled.div`
  position: relative;
  display: none;
  height: 300px;
  width: 300px;
  margin: 0 auto;
  overflow: hidden;
`;

const VisibleVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const HiddenVideo = styled.video`
  display: none;
`;
