import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const PricingDetails = () => (
    <Box mt={2} mb={3} border='1px solid' borderColor='#e2e2e2' padding={3} fontSize='smaller' borderRadius='5px'>
        <Typography fontSize='small' paragraph>
            * Contact counts are counted across all social accounts. For the <b>FREE</b> plan, this is the 
            number of contacts <b>generated</b>. For all other plans, it is the number of <b>active</b> contacts.
        </Typography>
        <Typography fontSize='small' paragraph>
            &#10013; For the <b>FREE</b> plan, this is the total number of automations allowed. For all other plans, 
            it is the total number of <b>active</b> automations.
        </Typography>
    </Box>
)

export default PricingDetails;