import {
  useReducer,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  useRef,
  useCallback,
  type JSX,
} from 'react';
import {Header, Loading, AlertBox} from 'components';
import {reducer, parseEmojis, flash} from 'lib';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import {
  query, 
  where, 
  limit, 
  orderBy,
  serverTimestamp,
  addDoc, 
  getDoc,
  setDoc,
  updateDoc,
  DocumentSnapshot,
  increment,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CommentIcon from '@mui/icons-material/Comment';
import { Messages } from './Messages';
import { Transition, Modal } from 'modals';

const containerId = 'kwixl-message-container';

export const ConversationModal = ({
  id,
  trigger = <IconButton 
    sx={{
      padding: {
        xs: 0,
        md: 'initial'
      }
    }}
  >
      <CommentIcon/>
  </IconButton>
}:{
  id: string;
  trigger?: JSX.Element,
}) => {

  const {
    firebaseUser, 
    defaultListenerOptions,
  } = useFirebaseContext();

  const {
    messagesRef,
    messageRef,
    messageThreadsRef,
    organizationCustomerRef,
    organizationRef,
  } = useRefs();

  const { mobile, organization } = useSessionContext();

  const timerRef = useRef<NodeJS.Timer | undefined>(undefined);

  const [{
    reply = '',
    open = false,
    ready = false,
    message = null,
  }, dispatch] = useReducer(
    reducer,
    {}
  );

  const [messages] = useCollection(
    query(
      messageThreadsRef!((open && id && message?.exists()) ? id : 'x'),
      where('createdAt', '!=', null),
      limit(250),
      orderBy('createdAt', 'asc'),
    ),
    defaultListenerOptions
  )

  const senderRecipient = useCallback(async () => {
    const [orgId = 'x', userId ='x'] = id.split(':');
    if (firebaseUser?.uid === userId) {
      const org = await getDoc(organizationRef!(orgId));
      return [
        { 
          id: firebaseUser?.uid,
          name: firebaseUser?.displayName,
        },
        {
          id: orgId,
          name: org?.get('name'),
        }
      ]
    } else {
      const customer = await getDoc(organizationCustomerRef!(orgId, userId))
        .catch((err:any) => console.log('Error getting customer', orgId, userId, err.message));
      return [
        {
          id: organization?.id,
          name: organization?.name || '',
        },
        { 
          id: userId,
          name: customer?.get('displayName') || '',
        },
      ]
    }
  },[id, firebaseUser, organization]);
  
  useEffect(() => {
    (async () => {
      if (!open || !id || !messagesRef) return;

      let doc: DocumentSnapshot | undefined;

      try {
        doc = await getDoc(messageRef!(id));
      } catch (err: any) {
        console.log(err);
      }

      if (!doc?.exists()) {
        const [orgId = organization?.id, customerId = ''] = id.split(':');
        if (!orgId || !customerId) {
          flash.error(`Invalid message indentifier!`);
          return;
        }
        let customerName = '';
        let orgName = '';
        if (organization?.id === orgId) {
          orgName = organization?.name || '';
          const customer = await getDoc(organizationCustomerRef!(orgId, customerId)).catch((err:any) => console.log('Error getting customer', orgId, customerId, err.message));
          if (customer?.exists()) customerName = customer.get('displayName');
        } else {
          const org = await getDoc(organizationRef!(orgId));
          if (org.exists()) orgName = org.get('name');
          customerName = firebaseUser?.displayName || '';
        }
        await setDoc(messageRef!(id), { 
            createdAt: serverTimestamp(),
            messageCount: 0,
            org: {
                id: orgId,
                name: orgName,
            },
            user: {
                id: customerId,
                name: customerName,
            },
            userUnread: 0,
            orgUnread: 0,
        });
        doc = await getDoc(messageRef!(id));
      }
      dispatch({ message: doc, ready: true });
    })();
  },[open, id, messageRef]);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(async () => await updateRead(), 2000);
  },[messages]);

  const updateRead = async () => {
    try {
      const unread = messages?.docs?.filter(doc => !doc.get('readAt') && (firebaseUser?.uid === doc?.get('to.id') || organization?.id === doc?.get('to.id'))) || [];
      if (unread.length > 0) {
        for (const m of unread) {
          await updateDoc(m.ref, { readAt: serverTimestamp() });
        }
      }
      if (firebaseUser?.uid === message?.get('user.id')) {
        await updateDoc(messageRef!(id), { userUnread: 0 });
      }
      if (organization?.id === message?.get('org.id')) {
        await updateDoc(messageRef!(id), { orgUnread: 0 });
      }
    } catch (err: any) {
      console.error(err.message);
    }
  }

  const handleChange = ({ target: {value}}: ChangeEvent<HTMLInputElement>) => {
    dispatch({reply: value});
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendReply();
    }
  };

  const sendReply = async () => {
    if (!reply?.trim()) return;
    const [from, to] = await senderRecipient();
    const payload = {
      author: {
        id: firebaseUser?.uid,
        name: firebaseUser?.displayName,
      },
      from,
      to,
      message: reply,
      createdAt: serverTimestamp(),
      readAt: null,
    };
    await addDoc(messageThreadsRef!(id || 'x'), payload);
    // Update main message thread
    if (message?.get('user.id') === firebaseUser?.uid) {
      await updateDoc(messageRef!(id), { messageCount: increment(1), orgUnread: increment(1), lastMessage: serverTimestamp() });
    } else {
      await updateDoc(messageRef!(id), { messageCount: increment(1), userUnread: increment(1), lastMessage: serverTimestamp() });
    }
    dispatch({reply: ''});
  };

  return (
    <Modal
        trigger={trigger} 
        fullScreen={mobile}
        fullWidth
        maxWidth='md'
        opened={() => dispatch({ open: true })}
        scroll='paper'
        closed={() => dispatch({ ready: false, open: false, id: null })}
        TransitionComponent={Transition}
      >
        <DialogTitle textAlign="center">
          <Header as="h5">
            {message?.get('org.id') === organization?.id && message?.exists() ? message?.get('user.name') || '' : message?.get('org.name') || ''}
          </Header>
        </DialogTitle>
          { open && (
            <DialogContent dividers id={containerId} sx={{ flexGrow: 1 }}>
                { (!ready) && <Loading/> }
                { (ready && message?.exists()) && <Messages thread={message} messages={messages?.docs as DocumentSnapshot[]}/> }
                { (ready && !message?.exists()) && <AlertBox severity="warning">Could not open conversation.</AlertBox> }
            </DialogContent>
          )}
            { (open && message?.exists()) && (
                <DialogContent dividers sx={{ flex: '1 0 110px', overflow: 'hidden' }}>
                    <form noValidate autoComplete='none'>
                        <TextField
                            multiline
                            maxRows={3}
                            fullWidth
                            id="message-reply"
                            placeholder={'Enter your message'}
                            value={parseEmojis(reply || '')}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="send" onClick={() => sendReply()}>
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                            variant="standard"
                        />
                    </form>
                </DialogContent>
            )}
    </Modal>
  );

};



