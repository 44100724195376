import { NumericFormat } from 'react-number-format';

export const Money = ({value = 0, basic = false, decimal = 2}) => (
  <NumericFormat
      displayType={'text'}
      decimalScale={decimal}
      fixedDecimalScale={true}
      value={value}
      thousandSeparator={true}
      prefix={basic ? '' : '$'}
  />
);
