import {useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFirebaseContext, useIntlText} from 'hooks';
import {flash, reducer} from 'lib';
import {ROUTES} from 'routes';
import {Header, IntlText, PrimaryButton} from 'components';
import Box from '@mui/material/Box';
import MailIcon from '@mui/icons-material/Mail';

const initialState = {
  loading: false,
};

interface Props {
  user: any,
  invite?: string,
}

export const EmailConfirmation = ({user, invite}: Props) => {
  const navigate = useNavigate();
  const {callable} = useFirebaseContext();
  const {intlText} = useIntlText();

  const [{loading}, dispatch] = useReducer(reducer, initialState);

  const sendEmailConfirmation = async () => {
    dispatch({loading: true});
    const {data, errors} = await callable!('auth-verify', {
      action: 'create',
      method: 'email',
      value: user.email,
      uid: user.uid, 
    });
    if (errors || !data || !data.success) {
      flash.error(intlText("error_sending_confirmation"));
      dispatch({loading: false});
      return;
    }
    navigate(ROUTES.verify_method('email'), {state: {user, invite, value: user.email }});
  };

  return (
    <Box mb={2}>
      {/*<Header as="h5"><IntlText id="title_confirm_email"/></Header>*/}
      <p>
        <IntlText id="message_email_verify"/>
      </p>
      <Box mt={1}>
        <PrimaryButton
          variant="contained"
          fullWidth
          loading={loading}
          disabled={loading}
          onClick={() => sendEmailConfirmation()}
          endIcon={<MailIcon/>}
        >
          <IntlText id="action_send_email_confirmation" />
        </PrimaryButton>
      </Box>
      <p>
        <small>
          * <IntlText id="message_check_spam" />
        </small>
      </p>
    </Box>
  );
};
